@import '../../../../styles/variables';

.#{$search-prefix}-quick-access-intervention {
  background-color: $search-white-100;

  &-container {
    padding: $search-spacing-16;
    margin-bottom: 1.2px;
    margin-top: 1.2px;
    position: relative;
  }

  &-wrapper {
    display: flex;
  }

  &__title {
    margin-bottom: $search-spacing-16;
    line-height: 1.25;
    color: $andes-gray-900-solid;
  }

  &__img-container {
    height: 48px;
    width: 48px;
    margin-right: $search-spacing-8;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .andes-thumbnail-container .andes-thumbnail {
    border: 1.2px solid #e5e5e5;
    border-radius: 6px;
    height: 48px;
    width: 48px;
  }

  &__img-container &__img,
  &__img-container &__icon {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  &__img-container &__img {
    object-fit: contain;
  }

  &__content {
    flex-grow: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: $search-spacing-2;
  }

  &__content-label-container {
    display: flex;
    align-items: center;
    gap: $search-spacing-4;
  }

  &__content-label {
    line-height: 1.25;
    color: #737373;
  }

  &__trailing {
    display: flex;
    align-items: center;
  }

  &-andes-blue-100 {
    background-color: $andes-blue-100;
  }

  &-gray-040-solid {
    background-color: $andes-gray-040-solid;
  }

  &__content-title {
    color: #1a1a1a;
    line-height: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 250px;
  }

  &__content-subtitle {
    color: #1a1a1a;
    line-height: 1.28;
    padding-right: 30px;
  }

  &__action-text {
    line-height: 1.28;
  }

  &__footer &__action {
    display: flex;
    align-items: center;
    padding-left: 0;
    padding-right: 0;
    height: $search-spacing-18;
  }

  &__action::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: $z-index-base;
  }

  &__chevron-container &__chevron {
    @include size($search-spacing-8, 10px);

    stroke-width: 1.8px;
  }

  &__footer &__chevron-container {
    width: $search-spacing-16;
    height: $search-spacing-16;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__content-description-text {
    line-height: 1.25;
    color: #737373;
  }

  .ui-search-icon--cockade {
    margin-left: 0;
    width: $search-spacing-10;
    height: $search-spacing-10;
  }

  &__components-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

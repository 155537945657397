@import '../../../../styles/variables';

.ui-search-billboard-intervention {
  display: block;
  height: 364px;
  margin: 30px auto;
  background-color: $search-billboard-gray;
  overflow-x: clip;

  .andes-carousel-snapped__controls-wrapper {
    height: 364px;
    border-radius: 6px;
    overflow: hidden;

    .andes-carousel-snapped__control.andes-carousel-snapped__control--previous.andes-carousel-snapped__control--size-large,
    .andes-carousel-snapped__control.andes-carousel-snapped__control--next.andes-carousel-snapped__control--size-large {
      display: none;
    }

    .andes-carousel-snapped {
      border-radius: 6px;
    }

    .billboard-intervention__slide--card-top {
      position: absolute;
      width: 100%;
      z-index: 1;

      .billboard-intervention__logo-image-container {
        background-color: $andes-white;
        border-radius: 6px;
        height: 70px;
        left: 0;
        position: absolute;
        top: 0;
        width: 70px;
        z-index: 1;
        margin: 8px;
      }

      .billboard-intervention__slide--card-top--logo {
        height: auto;
        border-radius: 6px;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 60px;
      }
    }

    .billboard-intervention__slide--card-img {
      object-fit: cover;
      object-position: center;
      position: absolute;
      border-radius: 6px;
    }
  }

  &__content {
    position: absolute;
    width: 100%;
    bottom: 0;
    background-color: $search-billboard-black-700;
    padding: 15px 8px 8px;
    color: $search-white-100;
    display: flex;
    flex-direction: column;

    & > span:first-child {
      margin-bottom: 4px;
    }

    &-label--XXSMALL {
      font-size: $font-size-12;
    }

    &-label--WHITE {
      color: $search-white-100;
    }

    &-label--REGULAR {
      font-weight: $font-weight-normal;
    }

    &-label--24 {
      font-size: $font-size-24;
    }

    .ui-search-billboard-intervention__action-button {
      margin-top: 20px;

      .andes-button__content .andes-button__text {
        color: $search-white-100;
      }
    }

    .ui-search-price {
      color: $search-white-100;
      margin-bottom: $search-spacing-8;
    }
  }
}

.ui-search-billboard-intervention--mot {
  margin: 0 10px;
}

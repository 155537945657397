@import '~ui-library_ml/src/components/empty-state/styles/empty-state';
@import 'vis-faceted-search/src/mobile/index';

$card-image-size: 100px;
$slide-horizontal-padding: $andes-spacing-12;
$slide-separation-size: 2.5px;
$slide-minimun-visible: $slide-horizontal-padding + $slide-separation-size;
$slide-width-small: calc(100vw - #{2 * $slide-minimun-visible});
$slide-width: 320px;
$loading-pill-background: #ecf4fd;

// sass-lint:disable no-vendor-prefixes

.#{$search-prefix}-layout--map {
  @import '~@andes/card/index';

  overflow: hidden;
  position: relative;

  .#{$search-prefix}-map-header {
    &__container {
      position: absolute;
      top: 0;
      width: 100%;
      display: flex;
      justify-content: center;
      text-align: center;
      padding: $andes-spacing-12 $andes-spacing-20 $andes-spacing-12;
      opacity: 1;
      z-index: 1;
      pointer-events: auto;
    }

    &__pill {
      background: $andes-white;
      border-radius: 16px;

      &--loading {
        border-radius: $andes-spacing-16;
      }

      &-progress {
        position: absolute;
        top: 0;
      }

      &-text {
        color: $andes-gray-550;
        line-height: inherit;
      }

      &-text--loading {
        margin-left: $andes-spacing-24;
        color: $andes-blue-500;
      }
    }
  }

  .#{$search-prefix}-map-results {
    width: 100vw;
    overflow: hidden;
    position: absolute;
    bottom: 0;
    transition: transform $transition-duration ease-out;
    transform: translate(0, 100%);

    &--show {
      transform: translate(0, 0);
    }

    &__slide {
      width: 100%;
      padding: 0 $slide-separation-size 10px $slide-separation-size;
    }

    &__carousel-container {
      max-width: $slide-width;
      margin: 0 auto;
    }

    @media (max-width: #{$breakpoint-360}) {
      &__carousel-container {
        max-width: $slide-width-small;
      }

      &__slide {
        width: $slide-width-small;
      }
    }
  }

  .#{$search-prefix}-result {
    min-height: 200px;
    padding: $andes-spacing-12 $slide-horizontal-padding;
    transform: translate3d(0, 0, 0);

    &__wrapper {
      display: flex;

      .#{$search-prefix}-bookmark__icon-bookmark,
      .#{$search-prefix}-bookmark__icon-bookmark-fill {
        top: 12px;
        right: 12px;
      }
    }

    &__main-image-button {
      position: relative;
      display: block;
      width: $card-image-size;
      height: fit-content;
      margin: 0 $andes-spacing-12 0 0;
      text-align: center;
      background: none;
      padding: 0;
      border: none;

      &__total-pictures {
        position: absolute;
        bottom: 0;
        transform: translate3d(-50%, 50%, 0);
      }
    }

    &__main-image-internal {
      border-radius: $border-radius-6;
      width: $card-image-size;
      height: $card-image-size;
      object-fit: cover;
    }

    &__content-link {
      overflow: hidden;
    }

    &__content {
      &-separator--4 {
        margin-bottom: $search-spacing-4;
      }

      &-separator--8 {
        margin-bottom: $search-spacing-8;
      }

      &-title {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      &-seller {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      &-online-visit-request {
        display: flex;
        align-items: center;

        svg {
          fill: $andes-text-color-primary;
          margin-right: $andes-spacing-4;
        }
      }

      &-attributes {
        color: $andes-gray-900;
        font-size: $font-size-12;
        font-weight: $font-weight-regular;
        line-height: 15px;
      }

      .#{$search-prefix}-price {
        color: $andes-gray-900;

        &__part {
          font-size: $font-size-18;
          line-height: 25px;
        }

        &__front-price {
          font-weight: $font-weight-regular;
          font-size: $font-size-12;
          line-height: 15px;
        }

        &::before,
        &::after {
          margin: 0;
        }
      }
    }
  }
}

.#{$search-prefix}-result__picture-swipe {
  background: $andes-gray-900;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  inset: 0;
  z-index: 1;
  padding: 0;
  margin: 0;
  border: 0;
  width: 100%;
  height: 100%;

  &__loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: 100px;
  }

  &__loading-text {
    margin-top: $andes-spacing-12;
  }

  &__carousel {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 500px;

    &__toolbar {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 $andes-spacing-20;
      margin-bottom: $andes-spacing-48;
      z-index: 2;

      &__close-button {
        background: none;
        border: none;
        padding: 0;
      }
    }

    &__picture {
      object-fit: contain !important;
    }

    @media (orientation: landscape) {
      max-height: 100%;

      &__toolbar {
        margin-top: $andes-spacing-24;
      }
    }
  }
}

.#{$search-prefix}-map-loader-error {
  .ui-empty-state {
    &__container {
      width: 100%;
      max-width: 80vw;
    }

    &__title {
      font-size: $font-size-18;
      font-weight: $font-weight-semibold;
      margin-bottom: $andes-spacing-16;
    }

    &__description {
      color: $search-gray-r060;
      font-weight: $font-weight-semibold;
    }
  }

  &__icon {
    width: 130px;
    height: auto;
  }
}

.#{$search-prefix}-map-snackbar {
  margin: 0;
  border-radius: 0;

  .andes-snackbar__message {
    padding: $andes-spacing-20;
    line-height: $line-height-s;
    font-size: $font-size-14;
    color: $andes-white;
    font-weight: $font-weight-regular;
  }
}

@import '../../../styles/variables';

// sass-lint:disable max-line-length
// sass-lint:disable no-misspelled-properties
.#{$search-prefix}-top-switch-filter {
  $root: &;

  width: 100%;
  display: flex;
  height: 46px;
  justify-content: space-between;
  align-items: center;
  background-color: $andes-white;
  padding-left: $andes-spacing-12;
  padding-right: $andes-spacing-20;
  border-top: 1px solid $andes-gray-070;
  position: relative;

  &__wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__description {
    display: flex;
    align-self: center;
  }

  &__label {
    font-size: $font-size-12;
    margin-left: 6px;
    font-weight: normal;
    flex-shrink: 0;
  }

  &__results {
    font-size: $font-size-14;
    color: $andes-gray-550;
    line-height: 16px;
    font-weight: $font-weight-regular;
    flex-shrink: 0;
  }

  &__tooltip {
    .andes-tooltip-button-close {
      top: 0.3em;
      right: 0.3em;
    }

    &.#{$search-prefix}-tooltip-filter .andes-tooltip__content {
      margin-right: $andes-spacing-24;
    }
  }

  .ui-search-item {
    &__highlight-label {
      margin-bottom: 0;
      height: fit-content;
      margin-left: $search-spacing-6;
      font-size: $font-size-12;
      padding: 1.5px 7px 0.5px 6px;
      flex-shrink: 0;

      &--no-background {
        padding-left: 0;
        margin-left: 0;
      }

      // sass-lint:disable nesting-depth

      &--virtual-tour {
        display: flex;
        align-items: center;

        .#{$search-prefix}-styled-label {
          margin-left: $andes-spacing-8;
        }
      }
    }
  }

  .ui-search-animated-switch {
    align-self: center;
  }

  &--RENTAL_TYPE_HIGHLIGHTED {
    padding-left: $andes-spacing-20;

    #{$root}__tooltip {
      transform: translateX(-5px);
    }
  }

  @media (width <= 360px) {
    padding-left: $search-spacing-10;
    padding-right: $andes-spacing-12;
  }
}

.#{$search-prefix}-top-switch-filter-custom {
  $root: &;

  width: 100%;
  padding: $andes-spacing-12 $search-spacing-10;
  justify-content: space-between;
  align-items: center;
  background-color: $andes-white;
  border-top: $search-spacing-1 solid $andes-gray-070;
  position: relative;

  &__main-wrapper {
    display: flex;
    justify-content: space-between;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
  }

  &__highlight-wrapper {
    display: flex;
    width: 100%;
  }

  &__label {
    font-size: $font-size-12;
    margin-left: $search-spacing-6;
    font-weight: normal;
    flex-shrink: 0;
  }

  &__credit-highlight {
    display: inline-block;
    color: var(--text-andes-text-color-inverted, #fff);
    font-family: Proxima Nova;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 15px;
    border-radius: 2px;
    background-color: var(--text-andes-text-color-positive, #00a650);
    padding: $search-spacing-2 $andes-spacing-4;
    margin-bottom: $andes-spacing-4;
    height: $andes-spacing-20;
  }

  &__main-text {
    display: inline-block;
    color: var(--text-andes-text-color-primary, rgba(0, 0, 0, 0.9));
    font-family: Proxima Nova;
    font-size: $font-size-14;
    font-style: normal;
    font-weight: $font-weight-semibold;
    line-height: $line-height-18;
    margin-bottom: $search-spacing-2;
  }

  &__filter-subtitle {
    display: inline-block;
    color: var(--text-andes-text-color-secondary, rgba(0, 0, 0, 0.55));
    font-family: Proxima Nova;
    font-size: 12px;
    font-style: normal;
    font-weight: $font-weight-regular;
    line-height: $line-height-15;
  }

  .melichoice-icon {
    position: relative;
    top: 1px;

    img {
      width: 19px;
      height: 13px;
    }
  }

  &__helper-action {
    display: inline;
    background: none;
    border: none;
    padding-left: $andes-spacing-8;
  }

  /* Removes default padding */
  &__action-modal > .andes-modal__scroll > .andes-modal__content > iframe {
    padding: 0 !important;
  }

  &__helper-action > .ui-search-icon {
    width: auto;
    height: auto;
  }

  .ui-search-animated-switch {
    align-self: top;
  }

  @media (width <= 360px) {
    padding-left: $search-spacing-10;
    padding-right: $andes-spacing-12;
  }
}

.#{$search-prefix}-toolbar + .#{$search-prefix}-top-switch-filter {
  height: 45px;
  border-top: none;
}

.#{$search-prefix}-top-switch-filter--COIN_HIGHLIGHTED {
  $padding-side: $search-spacing-12;

  justify-content: flex-start;
  width: 100%;
  height: auto;
  min-height: 46px;
  padding: $padding-side;
  border-top: $search-spacing-1 solid transparent;
  margin: -1px auto 0;
  box-shadow: 0 $search-spacing-1 0 rgba($andes-black, 0.0466202),
    0 $search-spacing-2 $search-spacing-4 rgba($andes-black, 0.14);

  &::before {
    content: '';
    display: block;
    width: calc(100% - (#{$search-spacing-12} * 2));
    height: $search-spacing-1;
    margin: auto;
    background: $search-gray-r010;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }

  .#{$search-prefix}-animated-switch {
    margin-left: auto;
  }

  .#{$search-prefix}-top-switch-filter-action {
    width: $search-spacing-17;
    height: $search-spacing-17;
    padding: $search-spacing-1;
    margin: auto 0 auto $search-spacing-8;
    border: none;
    background: none;

    &:focus-visible {
      outline: none;
    }

    &:first-of-type {
      margin-left: 0;
    }

    .#{$search-prefix}-icon {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
}

.#{$search-prefix}-animated-switch__switch-bar--off {
  // sass-lint:disable no-color-literals
  background: #b2b2b2;
}

.#{$search-prefix}-top-switch-filter {
  .#{$search-prefix}-composed-label__normal-text {
    line-height: 1;
  }

  &-modal {
    iframe {
      width: 100%;
      padding: $search-spacing-32;
      border: 0;
      -webkit-overflow-scrolling: touch;
      opacity: 0;
      animation: 0.65s showElement ease;
      animation-delay: 0.65s;
      animation-fill-mode: forwards;
    }

    .andes-modal__scroll {
      border-radius: 0;
    }

    .andes-modal__header {
      align-items: center;
      gap: $search-spacing-17;
      padding-top: $search-spacing-17;
      padding-bottom: $search-spacing-17;

      // sass-lint:disable no-color-literals
      background: #fff159;
    }

    // sass-lint:disable bem-depth
    .andes-modal__header__contents,
    [class='andes-modal__header__contents'] {
      order: 1;
    }

    .andes-modal__title {
      font-size: $font-size-18;
      font-weight: $font-weight-normal;
      color: $search-gray-r090;
      line-height: $line-height-22;
    }

    .andes-modal__close-button {
      order: 0;
      top: unset;
      left: unset;
      width: 21px;
      height: $search-spacing-18;
      margin: 0;
      position: relative;

      &::before {
        width: inherit;
        height: inherit;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        transform: scale(1.5);
        filter: brightness(0);
      }
    }

    @keyframes showElement {
      from {
        opacity: 0;
      }

      to {
        opacity: 1;
      }
    }
  }

  .#{$search-prefix}-item__highlight-label--melicoin {
    padding-top: 0;

    .#{$search-prefix}-item__highlight-label--composed-title {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: $search-spacing-4;
      border-radius: $search-spacing-2;
      padding: $search-spacing-2 $search-spacing-4;
      background: $search-melicoin-color-bg-pill;
      font-size: $search-spacing-12;
      font-weight: $font-weight-semibold;
      line-height: $line-height-16;
      color: $search-melicoin-color-text-pill;

      .#{$search-prefix}-icon {
        filter: drop-shadow(0 $search-spacing-1 $search-spacing-2 $search-gray-r010);
      }
    }
  }
}

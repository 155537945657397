@import '../../../../../styles/variables';

.ui-search-toolbar {
  &__tooltip {
    min-width: calc(100vw - 32px);
  }

  &__link {
    align-items: center;
    display: flex;
    height: 52px;
    justify-content: center;
    font-size: $font-size-14;
    font-weight: $font-weight-regular;
  }

  &__icon {
    width: $andes-spacing-16;
    height: $andes-spacing-16;
    margin-right: 9px;

    &--map {
      margin-right: 6px;
    }

    &--list {
      margin-right: 7px;
    }
  }
}

@import '../../../../styles/variables';

$gradient-trend-unit: 0deg, rgb(0 0 0 / 40%) 0.11%, rgb(0 0 0 / 0%) 37.77%;
$gradient-trend-unit2: 360deg, rgb(17 17 17 / 10%) 0.11%, rgb(0 0 0 / 0%) 100%;

@mixin size($width, $height) {
  width: $width;
  height: $height;
}

@mixin overlay() {
  position: absolute;
  display: flex;
  align-items: flex-end;
}

// sass-lint:disable no-vendor-prefixes
@mixin ellipsis {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.#{$search-prefix}-trend-intervention {
  padding: $search-spacing-24 0;

  &-item-slide {
    position: relative;
  }

  &__unit {
    display: flex;
    justify-content: center;
    margin: $search-spacing-16;
    position: relative;

    &-title-container {
      border-bottom: 1px solid $andes-gray-100-solid;
      min-height: 41px;
      display: flex;
      align-items: center;
    }

    &-title-container > h1 {
      @include ellipsis;

      color: $andes-black;
      font-size: $font-size-20;
      font-weight: $font-weight-semibold;
      margin: $search-spacing-8 $search-spacing-16;
    }

    .andes-card {
      min-width: 328px;
      min-height: 428px;
      width: 100%;
      box-shadow: 0 6px 16px $andes-gray-100;
    }

    .andes-card__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0;
    }

    &-img-container {
      display: flex;
      height: 300px;
      align-items: center;
      justify-content: center;

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }

    &-items {
      margin: 0 $search-spacing-8;
      display: flex;
      justify-content: space-between;
      margin-top: $search-spacing-4;
      margin-bottom: $search-spacing-4;

      .andes-thumbnail-container {
        width: calc(25% - $search-spacing-4);
        height: 78px;
        display: flex;
        justify-content: center;

        &:not(:last-child) {
          margin-right: $search-spacing-4;
        }

        .andes-thumbnail > img {
          max-height: 100%;
        }
      }

      .andes-thumbnail--square {
        @include size(75px, 78px);

        display: flex;
        border: unset;
        align-items: center;
      }
    }

    a {
      width: 100%;
    }
  }

  &__center {
    padding: $search-spacing-24 0;
    display: flex;
    flex-direction: column;

    &-container {
      display: flex;
      justify-content: center;
      margin-top: $search-spacing-12;
    }

    &-container a:first-child {
      margin-right: $search-spacing-8;
    }

    &__slide-img {
      max-height: 100%;
      max-width: 100%;
    }

    // sass-lint:disable no-misspelled-properties
    &__slide-img-container {
      min-width: 160px;
      min-height: 182px;
      display: flex;
      justify-content: center;
      width: 100%;
      align-items: center;
      aspect-ratio: 3 / 4;
    }

    &__card-container {
      min-width: 160px;
      min-height: 213.33px;
      width: 100%;
      overflow: hidden;
      border: 1px solid $andes-gray-100-solid;
      box-shadow: none;
    }

    &__overlay {
      @include overlay;
      @include size(100%, 100%);
    }

    .andes-carousel-free {
      display: flex;
      justify-content: center;
      margin-bottom: 0;
      padding-right: 0;

      &__list {
        width: 100%;
      }

      &__slide {
        width: calc(50%);

        a {
          width: 100%;
        }
      }
    }
  }

  &__card-container {
    width: 144px;
    overflow: hidden;
    border: 1px solid $andes-gray-100-solid;
    box-shadow: none;
  }

  &__slide {
    &-img {
      max-width: 100%;
      max-height: 100%;

      &-container {
        @include size(100%, 192px);

        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    &-name {
      @include ellipsis;

      margin: $search-spacing-7 $search-spacing-10;
      text-align: center;
    }
  }

  &__name-container {
    background-color: $search-white-100;
    height: 46px;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    border-top: 1px solid $andes-gray-100-solid;
  }

  &-carousel {
    margin-top: $search-spacing-12;
    padding: 0 $search-spacing-16;
    margin-bottom: $search-spacing-2;

    .andes-carousel-free__slide:not(:last-child) {
      margin-right: $search-spacing-8 !important;
    }
  }

  &-title {
    padding: 0 $search-spacing-16;
  }

  &__overlay {
    @include overlay;
    @include size(144px, 192px);
  }

  &__link {
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
    top: 0;
    left: 0;

    .andes-card__content {
      padding: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
    }
  }

  .andes-carousel-free {
    margin-bottom: 0;
  }
}

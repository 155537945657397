@import './value-proposition';

.#{$search-prefix}-value-proposition {
  line-height: 14px;

  &__pills {
    margin-right: 3px;

    .#{$search-prefix}-icon {
      margin-right: 4px;
    }
  }

  .#{$search-prefix}-item__highlight-label {
    font-size: $font-size-12;
  }
}

@media (width >= 360px) {
  .#{$search-prefix}-value-proposition {
    width: 102%;
  }
}

.#{$search-prefix}-value-propositions-list {
  @include text-crop(5px, 3px);
}

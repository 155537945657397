@import '../../../styles/variables';

// sass-lint:disable no-vendor-prefixes

$search-billboard-content-background-color: rgb(0 0 0 / 50%);
$search-billboard-placeholder-background: rgb(0 0 0 / 10%);

.#{$search-prefix}-carousel--billboard--lowend {
  overflow-x: auto;
}

.#{$search-prefix}-carousel--billboard {
  margin: 0 $search-spacing-10;
  padding: 0 0 $search-spacing-10;

  .#{$search-prefix}-billboard {
    &__card {
      background-color: $search-billboard-placeholder-background;
      border-radius: 6px;
      position: relative;
      height: 364px;
      width: 100%;
      display: block;
      margin: 0 auto;
      background-size: cover;
      background-position: 50%;
    }

    &__card-image {
      width: inherit;
      height: inherit;
      object-position: center;
      object-fit: cover;
    }

    &__content {
      border-radius: 0 0 6px 6px;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 50%;
      z-index: $z-index-base;
      text-align: center;
      color: $andes-white;
      background-color: $search-billboard-content-background-color;
    }

    &__title {
      font-size: $font-size-18;
      height: 44px;
      overflow: hidden;
      line-height: 1.22;
      position: absolute;
      top: $andes-spacing-12;
      width: 100%;
      padding: 0 $andes-spacing-12;
    }

    &__title-mot {
      font-weight: 100;
    }

    &__title--res {
      font-weight: 400;
    }

    &__logo-image-container {
      position: absolute;
      top: 0;
      left: 0;
      z-index: $z-index-base;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      margin: 10px;
      width: 70px;
      height: 70px;
      background-color: $andes-white;
      overflow: hidden;
    }

    &__logo-image {
      position: relative;
      width: 60px;
      height: auto;
    }

    &__logo-image--res {
      border-radius: 0;
    }

    &__action-button {
      background-color: $andes-blue-500;
      color: $andes-white;
      font-size: $font-size-14;
      font-weight: $font-weight-semibold;
      border-radius: 4px;
      height: 32px;
      padding: $andes-spacing-8;

      .button-label {
        position: relative;
        top: -1px;
      }
    }

    &__action-link {
      position: absolute;
      padding: $andes-spacing-8;
      width: 100%;
      left: 0;
      top: 134px;
    }

    &__attributes {
      font-size: $font-size-14;
      font-weight: $font-weight-regular;
      position: absolute;
      width: 100%;
      top: 53px;

      li {
        display: inline;
        list-style: none;

        &::before {
          content: '|';
          font-size: 100%;
          line-height: 12px;
          margin-right: 2px;
          margin-left: 3px;
        }

        &:first-child::before {
          content: '';
          display: none;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    &__description {
      display: block;
      font-size: $font-size-14;
      font-weight: $font-weight-regular;
      height: 18px;
      width: 100%;
      position: absolute;
      top: 56px;
      padding: 0 $andes-spacing-12;
      margin-bottom: $andes-spacing-8;
    }

    &__from {
      display: block;
      font-size: $font-size-14;
      font-weight: $font-weight-regular;
      width: 100%;
      height: 15px;
      position: absolute;
      top: 82px;
      padding: 0 $andes-spacing-12;
    }

    &__price,
    &__price--res {
      position: absolute;
      top: 94px;
      font-size: $font-size-32;
      padding: 0 $andes-spacing-12;
      line-height: 1.1;
      vertical-align: middle;
    }
  }
}

.#{$search-prefix}-carousel--billboard {
  .andes-carousel-snapped {
    border-radius: $border-radius-4;
    overflow: hidden;
  }
}

.slick-slide {
  border-radius: 6px;
  padding: $andes-spacing-12;
  min-width: 100vw;
}

.slick-slide:empty {
  display: none;
}

.slick-arrow {
  display: none;
}

.slick-dots {
  position: static;
  margin-top: 0;
  margin-bottom: 0;

  li {
    margin: 0 10px;

    button::before {
      font-size: 35px;
    }

    &.slick-active button::before {
      opacity: 1;
      color: $andes-blue-500;
    }
  }
}

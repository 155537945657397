// sass-lint:disable no-misspelled-properties
@import '../../../../../styles/variables';

.ui-search-meliplay-intervention--grid {
  padding: $search-spacing-4;
  display: flex;
  flex-direction: column;
  height: 100%;

  .ui-search-item__highlight-label__text {
    padding: $search-spacing-2 $search-spacing-4;
    font-weight: $font-weight-semibold;
    border-radius: 3px;
    font-size: $font-size-12;
    line-height: $line-height-15;
  }

  &__cover {
    background-color: $search-gray-5;
    display: flex;
    width: 100%;
    align-items: center;
    border-radius: 0 !important;
    flex-shrink: 0;

    &--link {
      width: 100%;
    }

    &--image {
      object-fit: cover;
      width: 100%;
    }
  }

  &__data {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: $search-spacing-12 $search-spacing-8;
    height: 100%;
  }

  &__title {
    line-height: $line-height-15;
    font-size: $font-size-14;
    font-weight: $font-weight-normal;
    color: $search-gray-1000;
  }

  &__description {
    color: $andes-text-color-secondary;
    font-size: $font-size-12;
  }

  &__label {
    display: flex;
    gap: $search-spacing-4;
    align-items: center;
    margin-top: 13px;

    &-text {
      color: $search-green-100;
      font-weight: $font-weight-normal;
      font-size: $font-size-12;
      line-height: $line-height-15;
    }
  }

  &__button {
    border: 1px solid !important;
    margin-top: 13px;

    .ui-search-icon--play {
      margin-right: $search-spacing-7;
    }
  }
}

@import '../../../../styles/variables';

.ui-search-andes-message-intervention {
  margin: $andes-spacing-8 $andes-spacing-12;

  .andes-message__text {
    padding-right: $search-spacing-16;
  }
}

.#{$search-prefix}-zrp-disclaimer {
  background-color: $search-zrp-disclaimer;
  font-size: $font-size-14;
  font-weight: $font-weight-regular;
  color: $andes-white;
  margin: 10px;
  transform: translateY(-5px);

  &__full-message {
    margin-right: 5px;
  }

  &__query {
    font-weight: $font-weight-semibold;
  }

  &__link {
    color: $andes-white;
    font-weight: $font-weight-bold;
    text-decoration: underline;

    &:hover {
      color: inherit;
    }
  }

  a,
  a:link,
  a:hover,
  a:visited {
    text-decoration: underline;
  }

  .andes-message__close--neutral {
    height: 10px;
    top: 19px;

    &::before {
      height: 13px;
    }
  }

  .andes-badge {
    margin: 0 10px;
  }
}

@import '../../../../styles/variables';

.#{$search-prefix}-item__spotlight {
  display: flex;

  // sass-lint:disable no-misspelled-properties
  gap: 6px;
}

.#{$search-prefix}-item__spotlight__highlight {
  margin-top: -2px;
  font-size: 14px;
  margin-right: 2px;
  margin-bottom: -4px;
  line-height: 16px;
}

.#{$search-prefix}-destaque__compatible__icon {
  margin-top: -2px;
}

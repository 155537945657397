@import '../../../styles/variables';

.#{$search-prefix}-money-picker-mobile {
  &__content {
    display: flex;
    padding: 16px 10px 13px;
  }

  &__btn {
    background-color: $andes-white;
    display: inline-flex;
    border: 1px solid $search-gray-700;
    font-size: $font-size-14;
    line-height: 2.3;
    flex-grow: 1;
    place-content: center;
    color: $andes-gray-250;
    width: 50%;
  }

  &__btn:first-of-type {
    border-radius: 8px 0 0 8px;
    border-right: none;
  }

  &__btn:last-of-type {
    border-radius: 0 8px 8px 0;
    border-left: none;
  }

  &__btn:disabled {
    background-color: $andes-gray-550;
    color: $andes-white;
  }

  &--separator {
    background-color: $search-gray-r010;
    display: block;
    height: 1px;
    margin: 0 12%;
  }
}

@import '../../../styles/variables';
@import '../../../styles/mixins';

.#{$search-prefix}-view-change__link {
  @include toolbar-buttons;
}

.#{$search-prefix}-view-change__icon {
  @include toolbar-buttons-icons;
}

.#{$search-prefix}-view-change__label {
  @include toolbar-buttons-label;
}

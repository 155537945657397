@import '../../../../styles/variables';
@import '../../../../styles/mixins';

// sass-lint:disable no-vendor-prefixes
.#{$search-prefix}-official-store-label {
  @include text-crop(4px, 3px);

  font-size: $font-size-12;
  margin-left: 0;
  -webkit-box-ordinal-group: 4;
  clear: left;
  font-weight: 400;
  line-height: $line-height-16;
  position: relative;
  display: flex;
  align-items: center;

  &__cockade {
    margin-left: $search-spacing-4;
  }
}

.official-store--pi {
  margin-top: 5px;
}

.#{$search-prefix}-related-searches {
  margin: 0 10px $andes-spacing-24;

  &__group {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 90%;
    margin: 0 auto;
  }

  &__title {
    flex: 1 0 100%;
    margin-bottom: $andes-spacing-8;
    font-size: $font-size-14;
    line-height: 2.3;
    color: $andes-gray-900;
    text-align: center;
    font-weight: $font-weight-semibold;
  }

  &__item {
    position: relative;
    margin: 0;

    &:not(:last-child)::after {
      content: '-';
      position: relative;
      margin: 0 4px;
    }
  }

  &__link {
    font-size: $font-size-14;
    line-height: 2.3;
    text-align: center;
    color: $andes-gray-900;
    text-decoration: none;
    margin: 0;
  }
}

@import '../../../styles/variables';

.#{$search-prefix}-alert__fab {
  position: fixed;
  bottom: $andes-spacing-16;
  right: $andes-spacing-16;
  box-shadow: none !important;

  &--bottom-sheet {
    label {
      font-size: $font-size-16;
    }
  }
}

.#{$search-prefix}-alert__info-tooltip-container,
.#{$search-prefix}-alert__info-tooltip {
  position: fixed;
  bottom: 50px;
  right: 7px;
  z-index: 50;
}

.#{$search-prefix}-alert__save-info-tooltip-container {
  .andes-button--loud path {
    stroke: none !important;
  }
}

.#{$search-prefix}-alert__save-info-tooltip-container,
.#{$search-prefix}-alert__save-info-tooltip {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 50;
}

.#{$search-prefix}-alert__save-modal {
  .andes-modal__close-button {
    left: 15px;
  }
}

.#{$search-prefix}-alert__modal-header {
  h1 {
    font-size: $font-size-24;
    font-weight: $font-weight-semibold;
    line-height: 30px;
    color: rgba(0, 0, 0, 0.9);
  }

  span {
    margin-top: $andes-spacing-12;
    font-weight: $font-weight-normal;
    font-size: $font-size-16;
    line-height: $line-height-20;
    color: rgba(0, 0, 0, 0.9);
    display: block;
  }
}

.#{$search-prefix}-alert__save-info-tooltip {
  width: 99vw;
}

.#{$search-prefix}-alert__modal-container {
  padding: 0 $andes-spacing-20;

  .andes-form-control__countdown {
    white-space: nowrap;
    width: auto;
  }
}

.#{$search-prefix}-alert__modal-close-icon {
  padding: 18px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .button-close {
    padding: 0;
    height: 0;
    margin-top: 10px;
  }

  .andes-button--transparent path {
    stroke: #000 !important;
  }
}

.#{$search-prefix}-alert__modal-content {
  margin-top: $andes-spacing-20;

  .andes-form-control__label {
    margin-left: 0;
    margin-bottom: $andes-spacing-16;
  }

  .#{$search-prefix}-alert__modal-input-label {
    font-size: $font-size-16;
    line-height: $line-height-20;
    font-weight: $font-weight-semibold;
    color: rgba(0, 0, 0, 0.9);
  }

  hr {
    height: 1px;
    background-color: $seach-background-spotlight-off;
    border: none;
    margin: $andes-spacing-20 0;
  }

  p {
    font-size: $font-size-16;
    line-height: $line-height-20;
    font-weight: $font-weight-semibold;
    color: rgb(0 0 0 / 90%);
  }

  .#{$search-prefix}-alert__notifications-switch {
    margin: $andes-spacing-28 0;
    padding: 0;
  }

  .#{$search-prefix}-alert__frequency-container {
    margin-bottom: 30px;
    .#{$search-prefix}-alert__frequency-dropdown {
      margin-bottom: 30px;
    }
  }

  .#{$search-prefix}-alert__submit-container {
    margin-bottom: $andes-spacing-20;
  }
}

.#{$search-prefix}-alert__info-tooltip-fixed-container {
  transform: translateZ(0);
}

.#{$search-prefix}-alert__card {
  display: block;
  margin: 10px;
  background-color: $andes-white;
  text-align: center;
  width: auto;
  border-radius: 4px;
  height: 59px;
}

.#{$search-prefix}-alert__card--edit {
  .#{$search-prefix}-alert__content {
    display: inline-block;
    margin-top: 13px;
  }

  .#{$search-prefix}-icon--alert-edit {
    top: 6px;
    right: 7px;
    position: relative;
  }

  .#{$search-prefix}-alert__action-link {
    margin-left: 5px;
    color: $andes-blue-500;
  }
}

.#{$search-prefix}-alert__card--new {
  .#{$search-prefix}-icon--alert-new {
    top: 6px;
    right: 7px;
    position: relative;
  }

  .#{$search-prefix}-alert__action-link {
    margin-left: 5px;
  }

  .#{$search-prefix}-alert__btn {
    background: none;
    border: none;
    color: $andes-blue-500;
    font-size: $font-size-16;
    font-weight: $font-weight-light;
    margin-top: 10px;
  }
}

@import '../../../../styles/variables';

.#{$search-prefix}-applied-filters {
  padding: 0 $andes-spacing-16 $andes-spacing-12 $andes-spacing-16;
  border-bottom: initial;

  .#{$search-prefix}-applied-filters__tag {
    display: inline-block;
    margin: 0 $andes-spacing-8 $andes-spacing-8 0;
  }

  // Override andes
  .andes-tag {
    font-size: $font-size-14;
    margin: 0;
    height: 32px;
    background-color: $andes-gray-040;
    border-color: transparent;

    &__label {
      color: $search-gray-620;
    }
  }

  .andes-tag__label:first-child {
    padding-bottom: 1px;
  }

  .andes-tag__close {
    font-size: 0.9em;
  }

  & + .#{$search-prefix}-carousel {
    .slick-slide {
      padding-top: 0;
    }
  }

  & + .#{$search-prefix}-layout--mosaic {
    margin-top: 0;
  }

  &--no-header {
    margin: 0;
    padding: $andes-spacing-12;

    .ui-search-applied-filters__tag {
      margin: 0;
    }
  }
}

@import '../../../styles/variables';

$search-official-store-header-link-shadow-color: rgb(51 51 51 / 16%);
$search-official-store-header-subtitle-color: rgb(0 0 0 / 45%);

// sass-lint:disable no-vendor-prefixes
.#{$search-prefix}-header--official-store {
  padding: 10px;
  background: $andes-white;
  height: 97px;
  display: flex;
  border-bottom: solid 1px $search-line-color;

  &__link {
    @include square(76px);

    min-width: 76px;
    min-height: 76px;
    text-align: center;
    background: $andes-white;
    border-radius: 8px;
    box-shadow: 0 1px 8px 0 $search-official-store-header-link-shadow-color;
    overflow: hidden;
    vertical-align: middle;
    display: inline-block;
  }

  &__image {
    max-height: 60px;
    max-width: 60px;
    transform: translateY(-50%);
    top: 50%;
    position: relative;
  }

  &__description {
    position: relative;
    margin: 0 0 0 24px;
    vertical-align: middle;
    display: inline-block;
    align-self: center;
  }

  &__title {
    font-size: 20px;
    font-weight: 600;
    width: 250px;
  }

  &__subtitle {
    font-size: 16px;
    font-weight: 400;
    padding-top: 2px;
    color: $search-official-store-header-subtitle-color;
  }
}

.#{$search-prefix}-footer-components {
  margin-bottom: (-$andes-spacing-64);

  &__wrapper {
    display: flex;
    justify-content: center;
  }

  &__container {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    margin-bottom: $andes-spacing-64;
    padding: 0 $andes-spacing-12;
    width: 100%;
  }
}

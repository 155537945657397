@import '../../../styles/variables';

.#{$search-prefix}-header--deal {
  display: block;
  min-height: 150px;
  margin: 0;
  background-position: center center;
  background-size: auto 100%;
  width: 100%;
}

@import '../../../../styles/variables';

// sass-lint:disable no-vendor-prefixes
@mixin specialized-title {
  margin-top: $search-spacing-8;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  text-overflow: ellipsis;
  word-break: break-word;
}

.#{$search-prefix}-filter-intervention {
  background-color: $andes-white;

  &-background {
    padding: $search-spacing-16 0;
  }

  &-title {
    font-size: $font-size-16;
    line-height: $line-height-20;
    color: $andes-gray-900;
    font-weight: weight(normal);
    display: inline-block;
    padding: 0 $search-spacing-16;

    &__SEMIBOLD {
      font-weight: weight(semibold);
    }
  }

  &-view-all {
    text-align: right;
    display: inline-block;
    width: 30%;
    font-size: $font-size-16;
    line-height: $line-height-20;
    font-weight: weight(semibold);
    vertical-align: top;
    padding-right: $search-spacing-24;
    padding-top: $search-spacing-2;

    .#{$search-prefix}-link {
      color: $search-dodger-blue;
    }

    .#{$search-prefix}-link:hover {
      color: $search-dodger-blue;
    }
  }

  &-container {
    border-top: 1px solid $andes-gray-040;
    border-bottom: 1px solid $andes-gray-040;

    &__FILTER_COLOR {
      padding: 11px 0 $search-spacing-20;
    }

    &__link {
      max-height: 95px;
    }

    .andes-carousel-free {
      margin-bottom: 0;
      max-height: 115px;

      &__slide {
        display: flex;
        flex-direction: column;
      }

      .andes-tag {
        margin: $search-spacing-16 0 0;
        height: $search-spacing-32;
        background-color: $andes-white;
        min-width: 44px;
        justify-content: center;
        border: solid 1.2px $andes-gray-100-solid;

        &__label {
          padding: 7px $search-spacing-8;
          line-height: 15px;
          font-weight: $font-weight-semibold;
        }

        &.skeleton {
          animation: skeleton-loading 1s linear infinite alternate;
          color: transparent;
        }
      }

      .andes-tag__tag-hover-surface {
        border-radius: 56px;
        border: 1.2px solid $search-gray-r025;
        background-color: $andes-white;
        font-size: $font-size-14;
        font-weight: $font-weight-regular;
        text-align: center;
        color: $andes-gray-900;
      }

      &::-webkit-scrollbar {
        display: none;
      }

      .ui-search-modal__link {
        font-size: $font-size-12;
        color: #000;
        font-weight: $font-weight-semibold;
        position: relative;
        top: -59px;
        display: flex;
        height: 80.2px;
        align-items: flex-end;
        justify-content: center;
        margin-top: 0;
        line-height: 10px;
      }
    }

    .andes-carousel-free ul {
      padding: 0 $search-spacing-16;
      display: flex;
      width: 100%;
    }

    .andes-carousel-free__list--spacing-32 .andes-carousel-free__slide:not(:last-child) {
      margin-right: $search-spacing-20;
    }

    .andes-carousel-free__slide:last-child {
      padding-right: $search-spacing-16;
    }

    .andes-tag__avatar {
      height: $search-spacing-24;
      width: $search-spacing-24;
      margin: $search-spacing-4 $search-spacing-8 $search-spacing-4 $search-spacing-4;
      border: $search-spacing-1 solid $search-gray-r007;
    }

    .andes-thumbnail {
      margin: auto;
      border: none;
    }
  }

  &__view-all-link {
    margin-top: $search-spacing-16;
    line-height: $search-spacing-28;
    font-size: $font-size-14;
    width: max-content;
  }

  &-text-item-slide {
    .#{$search-prefix}-filter-intervention_link {
      max-width: 44px;
    }
  }

  &-specialized-item-slide {
    text-align: center;
    width: 80px;
    margin-top: $search-spacing-14;

    .andes-thumbnail--80 {
      height: 84px;
      width: 84px;
    }

    .andes-thumbnail--56 {
      height: 56px;
      width: 56px;
      border: solid 1px $andes-gray-100-solid;
    }
  }

  &-specialized-title-large {
    @include specialized-title;

    -webkit-line-clamp: 2;
  }

  &-specialized-title-short {
    @include specialized-title;

    -webkit-line-clamp: 1;
    overflow: hidden;
  }

  &-specialized-subtitle {
    font-size: $font-size-12;
    line-height: $line-height-15;
    font-weight: weight(semibold_light);
    color: $search-gray-r080;
    display: block;
  }

  &-blue_10 {
    background-color: $andes-blue-100;
  }

  &-gray-040-solid {
    background-color: $andes-gray-040-solid;
  }
}

// Colors filter
$color-2450293: #1717ff;
$color-2450308: #fff;
$color-2450295: #000;
$color-2450312: #fcb1be;
$color-2450307: #f00;
$color-2450299: #666;
$color-2450294: #e1e1e1;
$color-2450291: #a0522d;
$color-2450326: #ff00ec;
$color-2450314: #0da600;
$color-2450325: #0f5299;
$color-2450303: #cbcfd0;
$color-2450322: #830500;
$color-2450311: #9f00ff;
$color-2450280: #fadbe2;
$color-2450278: #83ddff;
$color-2450296: #ffed00;
$color-2450281: #f5f3dc;
$color-2450306: #013267;
$color-2450327: #ff8c00;
$color-2450286: #ffe4c4;
$color-2450302: #6fa8dc;
$color-2450310: #3f7600;
$color-2450289: #ffd700;
$color-2450319: #9ff39f;
$color-2450292: #af8650;
$color-2450318: #fa8072;
$color-2450290: #4e0087;
$color-2450283: #e0ffff;
$color-2450324: #003d00;
$color-2450298: #dcecff;
$color-2450288: #1e6e7f;
$color-2450284: #ff51a8;
$color-2450320: #40e0d0;
$color-2450309: #bf9000;
$color-2450285: #d06ea8;
$color-2450321: #cc87ff;
$color-2450304: #eacb53;
$color-2450279: #7a64c6;
$color-2450313: #f9ac95;
$color-2450300: #ffffe0;
$color-2450287: #faebd7;
$color-2450317: #c63633;
$color-2450301: #f0e68c;
$color-2450316: #d9d2e9;
$color-2450315: #0ff;
$color-2450297: #5d3806;
$color-2450328: #d2691e;
$color-2450305: #73e129;
$color-2450282: #9b3f14;
$color-2450323: #fdaf20;

.#{$search-prefix}-filter-intervention-tag-color {
  &-2450293 {
    .andes-tag__avatar-text-container {
      background-color: $color-2450293;
    }
  }

  &-2450295 {
    .andes-tag__avatar-text-container {
      background-color: $color-2450295;
    }
  }

  &-2450308 {
    .andes-tag__avatar-text-container {
      background-color: $color-2450308;
    }
  }

  &-2450312 {
    .andes-tag__avatar-text-container {
      background-color: $color-2450312;
    }
  }

  &-2450299 {
    .andes-tag__avatar-text-container {
      background-color: $color-2450299;
    }
  }

  &-2450307 {
    .andes-tag__avatar-text-container {
      background-color: $color-2450307;
    }
  }

  &-2450294 {
    .andes-tag__avatar-text-container {
      background-color: $color-2450294;
    }
  }

  &-2450291 {
    .andes-tag__avatar-text-container {
      background-color: $color-2450291;
    }
  }

  &-2450326 {
    .andes-tag__avatar-text-container {
      background-color: $color-2450326;
    }
  }

  &-2450314 {
    .andes-tag__avatar-text-container {
      background-color: $color-2450314;
    }
  }

  &-2450325 {
    .andes-tag__avatar-text-container {
      background-color: $color-2450325;
    }
  }

  &-2450303 {
    .andes-tag__avatar-text-container {
      background-color: $color-2450303;
    }
  }

  &-2450322 {
    .andes-tag__avatar-text-container {
      background-color: $color-2450322;
    }
  }

  &-2450311 {
    .andes-tag__avatar-text-container {
      background-color: $color-2450311;
    }
  }

  &-2450280 {
    .andes-tag__avatar-text-container {
      background-color: $color-2450280;
    }
  }

  &-2450278 {
    .andes-tag__avatar-text-container {
      background-color: $color-2450278;
    }
  }

  &-2450296 {
    .andes-tag__avatar-text-container {
      background-color: $color-2450296;
    }
  }

  &-2450281 {
    .andes-tag__avatar-text-container {
      background-color: $color-2450281;
    }
  }

  &-2450306 {
    .andes-tag__avatar-text-container {
      background-color: $color-2450306;
    }
  }

  &-2450327 {
    .andes-tag__avatar-text-container {
      background-color: $color-2450327;
    }
  }

  &-2450286 {
    .andes-tag__avatar-text-container {
      background-color: $color-2450286;
    }
  }

  &-2450302 {
    .andes-tag__avatar-text-container {
      background-color: $color-2450302;
    }
  }

  &-2450310 {
    .andes-tag__avatar-text-container {
      background-color: $color-2450310;
    }
  }

  &-2450289 {
    .andes-tag__avatar-text-container {
      background-color: $color-2450289;
    }
  }

  &-2450319 {
    .andes-tag__avatar-text-container {
      background-color: $color-2450319;
    }
  }

  &-2450292 {
    .andes-tag__avatar-text-container {
      background-color: $color-2450292;
    }
  }

  &-2450318 {
    .andes-tag__avatar-text-container {
      background-color: $color-2450318;
    }
  }

  &-2450290 {
    .andes-tag__avatar-text-container {
      background-color: $color-2450290;
    }
  }

  &-2450283 {
    .andes-tag__avatar-text-container {
      background-color: $color-2450283;
    }
  }

  &-2450324 {
    .andes-tag__avatar-text-container {
      background-color: $color-2450324;
    }
  }

  &-2450298 {
    .andes-tag__avatar-text-container {
      background-color: $color-2450298;
    }
  }

  &-2450288 {
    .andes-tag__avatar-text-container {
      background-color: $color-2450288;
    }
  }

  &-2450284 {
    .andes-tag__avatar-text-container {
      background-color: $color-2450284;
    }
  }

  &-2450320 {
    .andes-tag__avatar-text-container {
      background-color: $color-2450320;
    }
  }

  &-2450309 {
    .andes-tag__avatar-text-container {
      background-color: $color-2450309;
    }
  }

  &-2450285 {
    .andes-tag__avatar-text-container {
      background-color: $color-2450285;
    }
  }

  &-2450321 {
    .andes-tag__avatar-text-container {
      background-color: $color-2450321;
    }
  }

  &-2450304 {
    .andes-tag__avatar-text-container {
      background-color: $color-2450304;
    }
  }

  &-2450279 {
    .andes-tag__avatar-text-container {
      background-color: $color-2450279;
    }
  }

  &-2450313 {
    .andes-tag__avatar-text-container {
      background-color: $color-2450313;
    }
  }

  &-2450300 {
    .andes-tag__avatar-text-container {
      background-color: $color-2450300;
    }
  }

  &-2450287 {
    .andes-tag__avatar-text-container {
      background-color: $color-2450287;
    }
  }

  &-2450317 {
    .andes-tag__avatar-text-container {
      background-color: $color-2450317;
    }
  }

  &-2450301 {
    .andes-tag__avatar-text-container {
      background-color: $color-2450301;
    }
  }

  &-2450316 {
    .andes-tag__avatar-text-container {
      background-color: $color-2450316;
    }
  }

  &-2450315 {
    .andes-tag__avatar-text-container {
      background-color: $color-2450315;
    }
  }

  &-2450297 {
    .andes-tag__avatar-text-container {
      background-color: $color-2450297;
    }
  }

  &-2450328 {
    .andes-tag__avatar-text-container {
      background-color: $color-2450328;
    }
  }

  &-2450305 {
    .andes-tag__avatar-text-container {
      background-color: $color-2450305;
    }
  }

  &-2450282 {
    .andes-tag__avatar-text-container {
      background-color: $color-2450282;
    }
  }

  &-2450323 {
    .andes-tag__avatar-text-container {
      background-color: $color-2450323;
    }
  }
}

@keyframes skeleton-loading {
  0% {
    background-color: $search-gray-600;
  }

  100% {
    background-color: $search-gray-200;
  }
}

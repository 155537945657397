@import './installments';

.#{$search-prefix}-installments {
  @include text-crop(4px, 4px);

  font-size: $font-size-12;
  margin-left: 0;
  clear: left;
  display: block;
  font-weight: $font-weight-regular;
  line-height: 1.35;

  position: relative;

  &-prefix {
    margin-right: 3px;
  }

  .#{$search-prefix}-price {
    margin-right: 5px;
    display: inline-flex !important;
    vertical-align: bottom;

    .price-tag-symbol {
      margin-right: 3px;
    }

    &__part {
      line-height: 1.35;
    }

    &__second-line {
      align-items: baseline;
    }

    &::after {
      content: '\200b'; // takes up height even when empty
    }
  }
}

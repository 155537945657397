// sass-lint:disable no-vendor-prefixes
$suggestions-border-color: #e5e5e5;

.#{$search-prefix}-suggestions {
  box-sizing: border-box;
  background-color: $search-gray-200;
  display: block;
  overflow: hidden;
  position: relative;
  height: auto;
  z-index: 2;
  padding: 15px 0 23px;

  &--collapse {
    height: 0;
    opacity: 0;
    visibility: hidden;
  }

  &__wrapper {
    clear: both;
    display: block;
    max-height: 0;
    opacity: 0;
    position: relative;
    transition: opacity, max-height 250ms ease-in-out;
    z-index: $z-index-xl;
    visibility: hidden;

    &--animated-open {
      opacity: 1;
      max-height: 600px;
      visibility: visible;
    }

    &--animated-close {
      opacity: 0;
      max-height: 0;
    }

    &::before {
      background-color: $search-gray-250;
      content: '';
      display: block;
      position: absolute;
      top: -7px;
      height: 12px;
      width: 12px;
      left: calc(49.5% + 50px);
      z-index: 1;
      transform: translateX(-40%) rotate(-45deg);
    }
  }

  .andes-badge {
    margin: 0 8px 0 0;
    transform: translateY(2px);
  }

  .andes-badge--small.andes-badge--notification .andes-message__badge--success {
    color: $search-suggestion-success-color;
  }

  .andes-badge--small.andes-badge--notification {
    height: 16px;
    width: 16px;
  }

  &__header {
    border-bottom: solid 1px $search-gray-r010;
    padding: 0 6%;
    margin: 0 0 15px;
    display: flex;

    &__title {
      color: $andes-gray-900;
      font-size: $font-size-16;
      font-weight: $font-weight-semibold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.38;
      letter-spacing: normal;
      margin: 0 20px 0 0;
      padding-bottom: 10px;
      width: 80%;
      position: relative;
      bottom: 1px;
    }
  }

  &__close {
    position: absolute;
    top: 18px;
    right: 24px;
    border: none;
    .#{$search-prefix}-icon--close {
      fill: $andes-gray-900;
      display: block;
    }
  }

  .andes-message__close--success {
    position: absolute;
    top: 10%;

    &::after,
    &::before {
      background: $andes-gray-550;
    }
  }

  &__subtitle {
    color: $andes-gray-900;
    font-size: $font-size-16;
    letter-spacing: normal;
    line-height: 1.43;
    margin: 0 0 11px;
    padding: 0 6%;
  }

  .andes-message__text {
    border-top: solid 1px $search-gray-r010;
  }

  &__list {
    margin: 0;
    max-height: 200px;
    display: flex;
    padding: 0 6%;

    &__column {
      width: 47.56%;
      text-align: center;
      margin-right: 5%;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__link {
    display: block;
    margin-bottom: 11px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__tag {
    width: 100%;
    height: fit-content;

    &.andes-tag {
      height: 36px;
      line-height: 14px;
      padding: 0;
      border-radius: 6px;
      border: 1px solid $suggestions-border-color;
      background-color: $andes-white;
      font-size: $font-size-14;
      font-weight: $font-weight-regular;
      text-align: center;
      color: $search-dodger-blue;
      min-width: 28px;
      display: flex;
      vertical-align: middle;
      padding-bottom: 1px;
    }

    .andes-tag__label {
      // Ajuste para evitar que queden cortadas algunas letras (ej: 'g')
      @include text-expand(0, 1px);

      color: $search-dodger-blue;
      position: relative;
      width: 100%;
      white-space: unset;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-word;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      visibility: visible;

      &:first-child {
        margin-left: 16px;
      }

      &:last-child {
        margin-right: 16px;
      }
    }
  }
}

@import 'price-details';

.#{$search-prefix}-price-details {
  font-size: $font-size-12;
  margin-top: -$search-spacing-2;

  .#{$search-prefix}-price--size-tiny .#{$search-prefix}-price__part.price-tag {
    font-size: $font-size-12;

    .price-tag-cents {
      font-size: $font-size-8;
    }
  }

  .#{$search-prefix}-price::before {
    margin-top: 0;
  }

  .#{$search-prefix}-price::after {
    margin-bottom: 0;
  }
}

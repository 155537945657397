@import '../../../../styles/variables';

.#{$search-prefix}-brand-disco-intervention {
  background-color: $search-white-100;
  width: 100%;

  &__header {
    padding: 0 $search-spacing-16;
    padding-bottom: $search-spacing-14;
  }

  .andes-carousel-free {
    padding: 0 $search-spacing-16;
    margin-bottom: 0;

    .andes-carousel-free__slide:not(:last-child) {
      margin-right: $search-spacing-8 !important;
    }
  }

  .card-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: $search-spacing-1 solid $andes-gray-100;
    border-radius: $search-spacing-6;
    overflow: hidden;
    background-color: $search-white-100;

    &-brand {
      font-size: $search-spacing-12;
      font-weight: $font-weight-semibold;
      padding: $search-spacing-3 $search-spacing-12;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-word;
      line-clamp: 1;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      color: $andes-black;

      &-container {
        min-height: 26px;
        display: flex;
        align-items: center;
      }
    }

    &-img {
      position: relative;
      min-height: 90px;
      min-width: 136px;

      .overlay-container {
        position: absolute;
        height: inherit;
        width: inherit;
        background: $andes-gray-040;
      }

      .image {
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-top-left-radius: $search-spacing-6;
        border-top-right-radius: $search-spacing-6;
      }
    }

    .card-content {
      padding: $search-spacing-8 $search-spacing-8 $search-spacing-4 $search-spacing-8;
      align-self: flex-start;
      position: relative;
      min-height: 47px;

      .card-price {
        display: flex;

        .andes-money-amount {
          margin-right: $search-spacing-5;
          color: $search-gray-1000;
        }

        .andes-money-amount__discount {
          -webkit-line-clamp: 1;
          line-clamp: 1;
          -webkit-box-orient: vertical;
          display: -webkit-box;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-word;
        }
      }

      &__description {
        height: 35px;
      }

      .ui-search-icon--full {
        width: $search-spacing-32;
        display: block;
      }
    }
  }

  &-action {
    display: flex;
    align-items: flex-end;

    &__label {
      padding: $search-spacing-16 $search-spacing-4 0 $search-spacing-16;
      font-family: $font-family-primary;
      font-style: normal;
      font-weight: $font-weight-semibold;
      font-size: $font-size-14;
      line-height: $line-height-18;
    }

    &__chevron {
      margin-bottom: -$search-spacing-2;
    }
  }

  .andes-carousel-snapped {
    height: 260px;
    padding: $search-spacing-6 0 0 $search-spacing-3;

    &__container {
      width: 63%;
    }
  }

  .andes-card__header {
    padding-bottom: $search-spacing-16;

    &--border {
      border-bottom: none;
    }
  }

  &-blue_10 {
    background-color: $andes-blue-100;
    padding: $search-spacing-14 0 $search-spacing-16 0;
  }

  &-white {
    background-color: $andes-white;
    padding: $search-spacing-16 0;
    margin: $search-spacing-16 0;
  }
}

@import '../../../styles/variables';
@import '~@andes/list/base';
@import '~@andes/list/lib/styles/list-item-selected';

.#{$search-prefix}-modal {
  .andes-list {
    font-weight: $font-weight-light;
    border-bottom: 1px solid $andes-gray-100;

    .andes-list {
      border-bottom: none;
    }
  }

  .andes-list__item {
    color: $andes-gray-900;
    padding-top: $andes-spacing-20;
    padding-bottom: $andes-spacing-20;
    border-top: 1px solid $andes-gray-100;

    &--selected::before {
      border-left-width: 6px;
    }

    &--with-disclaimer {
      justify-content: unset;

      &::after {
        content: $disclaimer-asterisk;
        position: relative;
        top: 1px;
      }
    }

    .disclaimer-asterisk {
      font-size: 19px;
    }
  }

  // update on modal version update
  .andes-modal__content {
    .andes-list__item-first-column {
      padding: 0;
    }

    // TODO: ajusto el z-index mientras se ajusta el area accionable del ListItem en andes
    .andes-list__item-action {
      z-index: $z-index-xxl;
    }
  }

  .andes-modal__header {
    padding: 101px 32px 53px;

    .andes-modal__title {
      font-size: 2rem;
    }

    &--sticky {
      .andes-modal__title {
        font-size: $font-size-20 !important;
        text-align: center;
      }
    }
  }
}

// modal overrides, should be a better way
.andes-modal--full .andes-modal__content {
  padding: 0 !important;
  z-index: 0;
}

.andes-modal__close-button {
  // sass-lint:disable no-vendor-prefixes //
  -webkit-tap-highlight-color: transparent;
  width: 35px;
  height: 35px;
  position: absolute;
  left: 27px;
  top: 16px;

  svg {
    display: none;
  }

  &::before {
    content: '';

    // sass-lint:disable max-line-length
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0ZWQgYnkgSWNvTW9vbi5pbyAtLT4KPCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4KPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIGZpbGw9IiMzNDgzZmEiIHN0cm9rZT0iIzM0ODNmYSIgd2lkdGg9IjMyIiBoZWlnaHQ9IjMyIiB2aWV3Qm94PSIwIDAgMzIgMzIiPgo8cGF0aCBzdHJva2Utd2lkdGg9IjAuMiIgZD0iTTYgMTUuNjY3bDkgOSAxLjMzMy0xLjY2Ny02LjMzMy02LjMzM2gxNnYtMmgtMTZsNi4zMzMtNi4zMzMtMS4zMzMtMS42Njd6Ij48L3BhdGg+Cjwvc3ZnPgo=');
    width: 35px;
    height: 35px;
    left: 0;
    top: 0;
    position: absolute;
    color: $andes-blue-500;
    text-indent: 0;
    transform: none;
    background-color: transparent;
  }

  &:focus {
    outline: none;
  }

  &::after {
    display: none;
  }
}

.#{$search-prefix}-modal--real-estate {
  .andes-modal {
    &__content {
      padding: 0;
    }

    &__header {
      height: 65.5px;
      min-height: 65.5px;
      text-align: center;
      border-bottom: 1px solid $andes-gray-100;
      box-shadow: 0 1px 0 0 $andes-gray-070, 0 0 24px 0 $andes-gray-100;
      z-index: 1;
      padding: $andes-spacing-28 0;

      .andes-modal__title {
        font-size: $font-size-20;
        box-sizing: content-box;
        position: absolute;
        top: 20px;
        left: 0;
        right: 0;
      }
    }

    &__header--sticky {
      padding: 28.5px 0;
    }

    &__close-button {
      left: 23px;
      top: 12px;
    }

    &__actions {
      box-shadow: 0 -2px 8px 0 $andes-gray-100;
    }
  }

  .andes-list {
    &--default {
      font-size: $font-size-16;
      border-bottom: none;
      box-shadow: 0 6px 16px $andes-gray-100;
      border-radius: $border-radius-6;
    }

    &__item {
      border-top: none;
    }
  }

  .#{$faceted-mobile-prefix} {
    box-shadow: none;

    .andes-checkbox__label.andes-checkbox__label-text {
      font-size: $font-size-12;
    }
  }
}

.andes-coach-marks {
  $scale-coach-mark: scale(0.96, 0.94);

  // sass-lint:disable bem-depth
  &__overlay {
    &__spotlight {
      transform: $scale-coach-mark;
    }
  }

  &__spotlight-border {
    transform: $scale-coach-mark;
  }

  &__step__close-button {
    svg {
      path {
        transform: scale(1.24);
        transform-origin: center;
      }
    }
  }

  &__tooltip__content-title {
    line-height: $line-height-25;
  }

  &__tooltip__content-text {
    font-weight: $font-weight-normal;
    line-height: $search-spacing-20;
  }
}

.__floater {
  &[x-placement='bottom'] {
    margin-top: $search-spacing-100;
  }
}

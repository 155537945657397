@import '../../../../styles/variables';

.#{$search-prefix}-rebates {
  margin-bottom: $search-spacing-12;

  &-rebate {
    margin-bottom: $search-spacing-4;
    flex-shrink: 1;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    &__pill {
      margin-bottom: $search-spacing-2;
      font-weight: $font-weight-semibold;
      line-height: $line-height-20;
    }

    &__text,
    &__text_nl {
      font-size: $font-size-12;
      color: $search-gray-450;
      font-weight: $font-weight-regular;
    }

    &__text_nl {
      margin-bottom: $search-spacing-2;
    }
  }

  .#{$search-prefix}-item__highlight-label__container {
    display: block;
  }
}

.#{$search-prefix}-price__discount_prefix {
  color: $search-gray-r080;
  font-size: $font-size-12;
  margin-right: $andes-spacing-4;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

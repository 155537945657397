.#{$search-prefix}-snackbar {
  margin: 0;
  border-radius: 0;

  .andes-snackbar__message {
    padding: 20px;
    line-height: 1.25;
    font-size: $font-size-14;
    color: $andes-white;
    font-weight: 400;
  }
}

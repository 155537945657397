@import '../../../../../styles/variables';

$tooltip-arrow-height: 1rem;

.#{$search-prefix}-tooltip-filter {
  width: fit-content;
  min-width: 100%;
  z-index: 1020;
  padding: 1em 0.75em;

  .andes-tooltip-arrow {
    display: none;
  }

  .andes-tooltip__content {
    margin-right: 0;
  }

  .andes-tooltip__title {
    font-size: 16px;
    margin: 0 0 4px;
    font-weight: $font-weight-semibold;
  }

  .andes-tooltip__text {
    font-weight: $font-weight-normal;
  }
}

@import './composed-label-alternatives';
.#{$search-prefix}-composed-label-alternatives {
  &__container {
    white-space: pre;
    line-height: 15px;
  }
  &__container {
    margin-bottom: 0;
  }
}

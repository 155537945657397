@import './value-propositions-details-modal';

// sass-lint:disable nesting-depth
.#{$search-prefix}-value-propositions-details-modal {
  &__title {
    font-size: $font-size-20;
    max-width: 257px;
    line-height: 20px;
    white-space: pre-line;
  }

  &__content {
    font-size: $font-size-16;
    width: 260px;
    top: 14px;
    padding-bottom: 30px;
  }

  &__text {
    line-height: 20px;

    &:not(:first-child) {
      margin-top: 20px;
    }
  }

  &__actions {
    width: 264px;
  }

  .#{$search-prefix}-icon {
    &--supermarket-logo {
      position: relative;
      width: 165px;
      height: 25px;
      top: 7px;
    }
  }

  .andes-modal__content {
    top: unset;
    transform: none;
    height: 100%;
    padding-top: 27px;
    padding-bottom: 15px;
  }

  .#{$search-prefix}-action {
    &--button {
      width: 100%;
      height: 48px;

      &:first-of-type {
        margin-bottom: $andes-spacing-8;
      }

      .#{$search-prefix}-action__label {
        line-height: 24px;
        border-radius: 6px;
        padding-top: $andes-spacing-12;
        padding-bottom: $andes-spacing-12;
      }
    }
  }
}

.andes-modal--light {
  .#{$search-prefix}-value-propositions-details-modal {
    &.andes-modal {
      border-radius: 6px;
    }

    &--VOLUME_DISCOUNT_V1 {
      &.andes-modal {
        max-width: calc(min(85vw, 312px));
        max-height: calc(min(80vh, 475px));
      }
    }
  }
}

@import '../../../styles/variables';
$VIS_RE_LOGO_HEIGHT: 40px;

.#{$search-prefix}-item__online-visit-container-grid-pi {
  margin-top: $andes-spacing-8;
  svg {
    vertical-align: middle;
    fill: $andes-text-color-primary;
    margin-right: $andes-spacing-8;
  }
  .#{$search-prefix}-item__online-visit-label {
    font-size: $font-size-14;
    font-weight: $font-weight-semibold;
    color: $andes-text-color-primary;
    line-height: $line-height-18;
  }
}

.#{$search-prefix}-item__online-visit-container-grid {
  display: flex;
  align-items: center;
  svg {
    fill: $andes-text-color-primary;
    margin-right: $andes-spacing-8;
  }
  .#{$search-prefix}-item__online-visit-label {
    font-size: $font-size-14;
    font-weight: $font-weight-semibold;
    color: $andes-text-color-primary;
    line-height: $line-height-18;
  }
}

.#{$search-prefix}-item__title-grid {
  .#{$search-prefix}-item__title {
    font-size: $font-size-14;
    font-weight: $font-weight-normal;
    color: $search-gray-r080;
    text-align: left;
  }
}

.#{$search-prefix}-item__price-grid {
  margin-top: 5px;
  margin-bottom: 5px !important;
  .#{$search-prefix}-price__front-price {
    font-size: $font-size-12;
    font-weight: $font-weight-normal;
    line-height: $line-height-15;
    color: $search-gray-r090;
  }
  .#{$search-prefix}-price .andes-money-amount__fraction,
  .#{$search-prefix}-price .andes-money-amount__currency-symbol {
    font-size: $font-size-24 !important;
    font-weight: $font-weight-normal;
    color: $search-gray-r090;
  }
}

.#{$search-prefix}-item__attributes-grid-container {
  margin: 0 0 $andes-spacing-4 0 !important;
  .#{$search-prefix}-item__attributes-grid li {
    font-size: $font-size-14;
    font-weight: $font-weight-normal;
    color: $search-gray-r090;
    display: block;
    list-style: none;
    line-height: $line-height-18;

    &:nth-child(-n + 2) {
      display: inline;
    }

    &:nth-child(2)::before {
      content: '|';
      vertical-align: baseline;
      line-height: 12px;
      margin-right: 5px;
      margin-left: 5px;
      font-size: 100%;
    }
  }
}

.#{$search-prefix}-item__location-grid-container {
  margin-bottom: $andes-spacing-4 !important;
  .#{$search-prefix}-item__location-grid-label {
    font-size: $font-size-14;
    font-weight: $font-weight-normal;
    color: $search-gray-r050;
  }
}

.#{$search-prefix}-item__description-container {
  margin-bottom: $andes-spacing-4;
  .#{$search-prefix}-item__location {
    line-height: $line-height-18 !important;

    &::before {
      margin-top: 0 !important;
    }

    &::after {
      margin-bottom: 0 !important;
    }
  }
}

.#{$search-prefix}-item__available-units-grid-label {
  font-size: $font-size-14;
  font-weight: $font-weight-semibold;
  color: $andes-gray-550;
}

.#{$search-prefix}-item__possession-grid-container {
  margin-bottom: 0 !important;
  .#{$search-prefix}-item__possession-label {
    font-size: $font-size-14;
    font-weight: $font-weight-normal;
    color: $search-gray-r050;
  }
}

.#{$search-prefix}-item__official-store-grid-container {
  margin-top: $andes-spacing-4;
  .#{$search-prefix}-official-store-label {
    font-size: $font-size-14;
    font-weight: $font-weight-normal;
    color: $search-gray-r050;
  }
}

.#{$search-prefix}-item__highlight-grid-container {
  margin-bottom: 0 !important;
}

.#{$search-prefix}-item__rental-type-grid-container {
  margin-bottom: 5px !important;
  .#{$search-prefix}-item__group--rental-type {
    margin-bottom: 0 !important;
  }
}

.#{$search-prefix}-result__real-estate-logo-container {
  position: relative;

  img {
    background-color: $search-white-100;
    border: solid 1px $search-gray-93;
    border-radius: $andes-spacing-4;
    object-fit: contain;
    padding: $andes-spacing-4;
    width: 53px;
    height: $VIS_RE_LOGO_HEIGHT;
    min-height: $VIS_RE_LOGO_HEIGHT;
    position: absolute;
    right: $andes-spacing-16;
    bottom: -$andes-spacing-20;
    z-index: 1;
  }
}

// sass-lint:disable no-vendor-prefixes
.#{$search-prefix}-layout--gallery {
  padding: 0 $andes-spacing-16 14px;

  .poly-card--grid-card {
    max-width: initial;

    .poly-card__portada {
      height: 100%;
    }
  }

  .#{$search-prefix}-result {
    margin: $andes-spacing-16 0;
    padding: 0;
    box-shadow: 0 10px 16px 0 $search-result-gallery-shadow-color;
    border-radius: 6px;

    &__progress-bar-container {
      width: 100vw;
      min-height: 284px;
      background: #eee;
      max-height: 284px;
      max-width: 100%;
      margin-left: -1;
      position: relative;
    }

    a:first-child:not([class^="poly"]) {
      text-align: center;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &__image_container {
      max-height: 450px;
      min-height: 100px;
      margin: 0;
      font-size: 0;
      line-height: 1;
      overflow: hidden;
      border-radius: 6px 6px 0 0;

      &--res {
        max-height: 350px;
        min-height: 200px;
      }
    }

    &__image_container img {
      width: 100%;
      height: auto;
      max-height: 450px;
      object-fit: contain;
    }

    &__image_container--gallery img {
      width: 100vw;
      max-width: 100%;
      max-height: 284px;
      min-height: 284px;
      object-fit: cover;
      object-position: center;
    }

    &__image_container--res img {
      width: auto;
      max-width: 100%;
      max-height: 350px;
      min-height: 200px;
      object-fit: cover;
    }

    &__image_container--res--gallery img {
      width: 100vw;
      max-width: 100%;
      max-height: 284px;
      min-height: 284px;
      object-fit: cover;
      object-position: center;
      height: 100%;
    }

    &__content {
      position: relative;
      padding: 16px;

      //TITLE
      .#{$search-prefix}-item__group--title {
        .ui-search-link::before {
          content: '';
          position: absolute;
          bottom: 0;
          width: 100%;
          height: 100%;
          z-index: 0;
          left: 0;
        }
      }
    }

    // HIGHLIGHTS
    .#{$search-prefix}-item__highlight-label--TIME_LABEL_HIGHLIGHT {
      font-size: $font-size-12;
    }

    // RENTAL-TYPE
    .#{$search-prefix}-item__group--rental-type {
      margin-bottom: $search-spacing-12;

      & .#{$search-prefix}-item__highlight-label {
        margin: 0;
      }
    }

    .#{$search-prefix}-item__subtitle {
      font-size: $font-size-12;
      line-height: 15px;
      display: block;
      color: $search-gray-r050;
      font-weight: $font-weight-normal;
      margin-bottom: 3px;
    }

    .#{$search-prefix}-item__highlighted-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      margin-top: 10px;

      & .#{$search-prefix}-item__highlighted {
        font-size: $font-size-12;
        color: $search-dodger-blue;
        font-weight: $font-weight-normal;
        line-height: $line-height-15;
      }

      & .#{$search-prefix}-item__group {
        margin-bottom: 0;
      }
    }

    .#{$search-prefix}-item__highlighted-wrapper.flex-end {
      justify-content: flex-end;
    }

    &__content--core {
      .#{$search-prefix}-price {
        &__second-line__label {
          margin-left: 0;
        }
      }
    }

    &__content--pill-p {
      padding-bottom: 40px;
    }

    .#{$search-prefix}-item__highlight-label {
      &--pricing_rebates {
        display: inline-flex;
        margin-right: 5px;
        font-size: $font-size-12;
        margin-bottom: 0;
        max-height: $font-size-18;
        line-height: $font-size-14;
      }
    }

    .#{$search-prefix}-installments {
      font-size: $font-size-12;
      line-height: 1.35;

      &::after {
        content: '\200b'; // takes up height even when empty
      }
    }

    .#{$search-prefix}-price__discount_prefix {
      font-size: $font-size-14;
    }

    .#{$search-prefix}-price__discount {
      font-size: $font-size-12;
    }

    .#{$search-prefix}-installments {
      .#{$search-prefix}-price--size-x-tiny {
        font-size: $font-size-12;
      }
    }
    &__item-image-container {
      .andes-carousel-snapped--scroll-hidden {
        margin: 0;
        padding: 0;
      }
    }
  }

  .#{$search-prefix}-result--srv {
    &__content {
      padding-top: 3px;
    }
  }

  .#{$search-prefix}-item__attributes li {
    display: inline;
    list-style: none;

    &::before {
      content: '\b7';
      font-size: 200%;
      vertical-align: sub;
      line-height: 12px;
      margin-right: 2px;
      margin-left: 3px;
    }

    &:first-child::before {
      content: '';
      display: none;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .#{$search-prefix}-item__attributes--res li {
    color: $search-text-color;
    font-weight: $font-weight-semi-bold-light;
  }

  .#{$search-prefix}-item__attributes--srv li {
    color: $andes-gray-550;
    font-weight: $font-weight-regular;
    line-height: 1;
  }

  .#{$search-prefix}-item__attributes--mot {
    li {
      font-weight: $font-weight-regular;
      color: $andes-gray-550;
    }
  }

  .#{$search-prefix}-bookmark__btn::before {
    content: '';
    background: rgba($andes-white, 0.7);
    width: 31px;
    height: 31px;
    position: absolute;
    top: 7px;
    right: 9px;
    border-radius: 50%;
    z-index: $z-index-base;
  }

  .#{$search-prefix}-bookmark .#{$search-prefix}-icon--bookmark {
    z-index: $z-index-s;
  }

  .#{$search-prefix}-item__location {
    color: $search-res-location-color;
    font-size: $font-size-12;
    line-height: $line-height-16;
    font-weight: $font-weight-semi-bold-light;
  }

  .#{$search-prefix}-item__pub-label {
    right: $andes-spacing-16;
  }

  .#{$search-prefix}-filter-intervention-container {
    margin: $andes-spacing-16 -$andes-spacing-16;
  }

  .#{$search-prefix}-item__available-units {
    font-size: $font-size-12;
    font-weight: $font-weight-semibold;
    color: $andes-gray-550;
  }
}

.#{$search-prefix}-layout--grid {
  .#{$search-prefix}-layout__item {
    margin-left: 0;

    .ui-search-result {
      .poly-card--grid-card {
        .poly-card__content .poly-component__title {
          -webkit-line-clamp: 3;
          max-height: calc(var(--poly-general-title-size) * 3 * 1.25);
        }
      }
    }

    &--intervention {
      grid-column: 1 / -1;
      width: 100%;
    }
  }
}

.ui-search-result__shops-pills--absolute {
  display: none;
  left: 0;
  padding: 0;
  position: absolute;
  text-align: center;
  top: 0;
  z-index: 1;

  .ui-pb {
    margin: 0;
  }
}

@import '../../../../styles/variables';
@import '../../../../styles/mixins';

// sass-lint:disable no-vendor-prefixes
.#{$search-prefix}-item__attributes {
  @include text-crop(4px, 4px);

  list-style: none;
  padding: 0;
  font-size: $font-size-12;
  -webkit-box-orient: vertical;
  line-height: $line-height-16;
}

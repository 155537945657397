// sass-lint:disable no-vendor-prefixes
.ui-search-info-text-box {
  &--mosaic {
    padding: 0 $andes-spacing-12;
  }

  &__container {
    position: relative;
    height: 100%;
    overflow: hidden;
    transition: max-height $quick-transition-duration cubic-bezier(0.9, 0, 0.8, 0.2);

    &--is-collapsed {
      transition: max-height $quick-transition-duration cubic-bezier(0.9, 0, 0.8, 0.2);
    }
  }

  &__title {
    font-size: $font-size-16;
    font-weight: $font-weight-semibold;
    line-height: 22px;
    color: $andes-gray-900;
    text-transform: capitalize;
  }

  &__details {
    margin: $andes-spacing-8 0;
    font-size: $font-size-14;
    line-height: 22px;
    font-weight: $font-weight-regular;
    color: $andes-gray-550;

    &--is-collapsed {
      max-height: 108px;
      overflow: hidden;
      display: block;
      background: linear-gradient(to bottom, $andes-gray-550 44%, transparent);
      background-clip: text;
      color: transparent;

    }

    a {
      display: inline !important;
    }
  }

  &__button {
    position: relative;
    border: 0;
    padding: 0 $andes-spacing-24 0 0;
    margin: 0;
    box-shadow: none;
    font-size: $font-size-14;
    color: $andes-blue-500;
    cursor: pointer;
    transition: color $quick-transition-duration;
    outline: none;
    background-color: transparent;

    &:hover {
      color: $andes-blue-600;
      transition: color $quick-transition-duration;
    }

    &--is-collapsed {
      .ui-search-info-text-box__button-icon {
        transform: rotate(90deg);
        transition: transform $quick-transition-duration;
      }
    }
  }

  &__button-icon {
    position: absolute;
    top: 5px;
    right: 6px;
    width: 8px;
    transform: rotate(270deg);
    transition: transform $quick-transition-duration;
  }
}

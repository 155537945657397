.#{$search-prefix}-spotlight {
  display: flex;
  justify-content: center;
  margin: 18px 0;
  font-weight: $font-weight-semibold;
  font-size: $font-size-14;
  line-height: 1.45;
  color: $search-gray-1000;

  &__link {
    margin-left: 6px;
  }

  &__link,
  &__switch-bar {
    width: 42px;
    display: inline-block;
    height: 22px;
  }

  &__switch-bar {
    border-radius: 25px;
    position: relative;

    &::after {
      display: block;
      content: '';
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background: $andes-white;
      margin: 2px;
      box-shadow: -1px 1px 2px $andes-gray-250;
      position: absolute;
      left: 0;
    }

    &.animated::after {
      animation: switchOff 150ms;
    }

    &--off {
      background: $seach-background-spotlight-off;
    }

    &--on {
      background: $andes-blue-500;

      &::after {
        left: 20px;
      }

      &.animated::after {
        animation: switchOn 150ms;
      }
    }
  }

  &--toolbar-variant {
    align-items: center;
    margin: 0;
    font-weight: $font-weight-regular;
    border: none;
    background-color: transparent;
  }

  &--toolbar-variant & {
    &__link,
    &__switch-bar {
      width: 32px;
      display: inline-block;
      height: 16px;
    }

    &__switch-bar {
      &::after {
        width: 12px;
        height: 12px;
      }

      &.animated::after {
        animation: switchOffExp 150ms;
      }

      &--on {
        &::after {
          left: 16px;
        }

        &.animated::after {
          animation: switchOnExp 150ms forwards;
        }
      }
    }
  }

  &__spotlight-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 $andes-spacing-8;
    position: relative;
    min-width: 160px;

    &::after {
      content: '';
      position: absolute;
      bottom: 16px;
      height: 20px;
      right: 0;
      border-right: 1px solid $search-line-color;
    }

    &--wide {
      padding: 0 $andes-spacing-8;
    }

    @media (min-width: $breakpoint-360) {
      padding: 0 $search-spacing-14;
    }
  }
}

@keyframes switchOn {
  from {
    left: 0;
  }

  to {
    left: 20px;
  }
}

@keyframes switchOff {
  from {
    left: 20px;
  }

  to {
    left: 0;
  }
}

@keyframes switchOnExp {
  from {
    left: 0;
  }

  to {
    left: 16px;
  }
}

@keyframes switchOffExp {
  from {
    left: 16px;
  }

  to {
    left: 0;
  }
}

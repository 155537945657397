@import '~@andes/button/lib/styles/size/medium';

.#{$search-prefix}-card-add-to-cart {
  &__no-items-button {
    padding: 0;
  }

  &__minimum-label {
    color: $search-gray-666;
    font-size: $font-size-12;

    &--grid {
      padding-top: 4px;
      position: absolute;
    }
  }
}

// Reset close button style for page X modal
.ui-ms-iframe-modal--mobile {
  .andes-modal__close-button {
    width: initial;
    height: initial;
    left: initial;

    &::before {
      display: none;
    }
  }

  svg {
    display: block;
  }
}

@import '../../../../styles/variables';
@import '~@andes/normalize/index';

.#{$search-prefix}-content-intervention {
  // Carousel
  @import '~@recommendations-fe/carousel-free/src/styles/recommendations-fe-carousel-free.mobile';

  // Card Global
  @import '~@recommendations-fe/card/src/styles/recommendations-fe-card-vertical.mobile';

  // Card Bundles
  @import '~@recommendations-fe/card/src/styles/bundles/recommendations-fe-card-vertical--for-carousel-free';

  padding: $search-spacing-24 0 0;
}

.ui-search-shopping-cart-intervention {
  .card-item {
    .card-content {
      &__pills-content {
        .rebates-pill {
          font-size: 10px;
          line-height: $andes-spacing-20;
        }

        .rebates-pill > svg {
          margin-left: 0;
          transform: scale(1.1) translateY(3px);
        }

        &:has(.rebates-pill) {
          padding-block: 0 3px;
        }
      }
    }
  }
}

.repurchase-intervention-mobile {
  padding: $search-spacing-12 0 $search-spacing-16 $search-spacing-16;
  margin: $search-spacing-16 0;

  .andes-carousel-free-title {
    font-size: $font-size-16;
    font-weight: 600;
    line-height: $search-spacing-20;
  }

  .andes-carousel-free {
    margin-top: $search-spacing-7;
  }

  .ui-search-price__second-line {
    align-items: flex-start;
  }

  .ui-search-price__second-line .andes-money-amount__currency-symbol,
  .ui-search-price__second-line .andes-money-amount__fraction {
    font-size: $font-size-14 !important;
  }
}

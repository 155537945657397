@import '../../../styles/variables';

.#{$search-prefix}-top-keywords {
  margin-top: $andes-spacing-16;
  margin-bottom: 30px;

  .andes-list__item-first-column {
    padding: 0;
  }

  &__title {
    font-size: $font-size-14;
    margin-bottom: 17px;
    margin-left: 10px;
    color: $andes-gray-550;

    &::after {
      content: ':';
    }
  }

  .andes-list__item--size-medium {
    height: auto;
  }

  &__list__item {
    padding-left: 45px;
    padding-top: 22px;
    padding-bottom: 21px;
    font-size: $font-size-14;
    color: $andes-gray-550;
    border-bottom: 1px solid $andes-bg-primary;

    .andes-list__item-primary {
      color: $top-keywords-item;
    }

    .andes-list__item-tertiary {
      position: absolute;
      left: 12px;
      transform: translate(3px, 2px);
    }
  }
}

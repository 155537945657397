@import '../../../../styles/variables';

@mixin variations-common {
  display: flex;
  align-items: center;
  width: 48px;
  height: 48px;
  justify-content: center;
  object-fit: contain;

  span {
    color: $search-dodger-blue;
    font-weight: $font-weight-normal;
    font-size: $font-size-12;
  }
}

@mixin img-44 {
  width: 44px;
  height: 44px;
}

@mixin img-40 {
  width: 40px;
  height: 40px;
}

.#{$search-prefix}-variations-picker-mobile {
  display: flex;
  justify-content: flex-start;
  margin-top: -$search-spacing-8;
  margin-bottom: $search-spacing-16;

  &__variation {
    @include variations-common;

    border: 1px solid $search-gray-1250;
    border-radius: $search-spacing-4;
    margin-right: $search-spacing-8;

    &-selected {
      @include variations-common;

      border: 2px solid $search-dodger-blue;
      border-radius: $search-spacing-3;
      margin-right: 7px;
    }
  }
}

// sass-lint:disable no-qualifying-elements
a.#{$search-prefix}-variations-picker-mobile__variation {
  margin-right: $andes-spacing-8;
}

@media (width <= 360px) {
  .#{$search-prefix}-variations-picker-mobile {
    &__variation {
      @include img-44;

      &-selected {
        @include img-44;
      }
    }
  }
}

@media (width <= 320px) {
  .#{$search-prefix}-variations-picker-mobile {
    &__variation {
      @include img-40;

      &-selected {
        @include img-40;
      }
    }
  }
}

@media (width <= 330px) {
  .#{$search-prefix}-variations-picker-mobile {
    margin-left: -($search-spacing-6);
  }
}

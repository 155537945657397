@import '../../../styles/variables';
@import '~@andes/carousel-snapped/index';

// sass-lint:disable no-vendor-prefixes

.#{$search-prefix}-carousel {
  $self: &;

  padding-top: 10px;
  padding-bottom: 10px;

  &--OFFICIAL_STORES .carousel-mobile,
  &--BRANDS .carousel-mobile,
  &--CPG {
    &-list {
      display: flex;
      margin: 0 $andes-spacing-16 $andes-spacing-20;
    }

    &-list-element {
      display: flex;
      position: relative;
      vertical-align: middle;
    }
  }

  &--BRANDS,
  &--OFFICIAL_STORES,
  &--CPG {
    .carousel-mobile {
      width: 100%;
    }

    .#{$search-prefix}-carousel {
      &__container:first-child {
        padding-left: 10px;
      }
    }

    & ~ .#{$search-prefix}-layout--stack,
    & ~ .#{$search-prefix}-layout--mosaic {
      margin-top: 0;
    }

    & ~ .#{$search-prefix}-layout--gallery {
      margin-top: -$andes-spacing-16 + 1;
    }
  }

  &--OFFICIAL_STORES {
    #{$self}__item {
      @include size(255px, 64px);

      background-color: $andes-white;
      border-radius: $border-radius-4;
      margin-right: 10px;

      &__image-container {
        @include size(65px, 64px);

        border-right: 1px solid $andes-gray-070;
        display: flex;
        justify-content: center;
      }

      &__data {
        margin: 10px;
        margin-left: 14px;
      }

      &__image {
        @include square(55px);

        position: relative;
        top: 50%;
        transform: translateY(-50%);
      }

      &__data-name {
        color: $andes-gray-900;
        font-size: $font-size-16;
        font-weight: $font-weight-semibold;
        line-height: 1.375;
        white-space: nowrap;
        width: 165px;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &__data-label {
        color: $andes-gray-550;
        font-size: $font-size-14;
        font-weight: $font-weight-regular;
        margin-top: 1px;
      }

      #{$self}__item--container {
        width: 255px;
        display: flex;
        box-shadow: 0 1px 2px 0 $search-result-gallery-shadow-color;
        inset: 0;
      }
    }
  }

  &--BRANDS {
    height: auto;

    #{$self}__item {
      @include square(86px);

      margin-right: 10px;

      &__header {
        display: none;
      }

      &--container {
        @include square(86px);

        border-radius: $border-radius-4;
        box-shadow: 0 1px 2px 0 $search-result-gallery-shadow-color;
        overflow: hidden;
      }

      &__image-container {
        align-items: center;
        background-color: $andes-white;
        display: flex;
        height: 100%;

        &--VIEW_MORE {
          border-radius: $border-radius-4;
          box-shadow: 0 1px 2px 0 $search-result-gallery-shadow-color;
          justify-content: center;
        }

        .ui-search-modal__link {
          @include square(100%);

          font-size: $font-size-14;
          font-weight: $font-weight-regular;
          justify-content: center;
          align-items: center;
          display: flex;
        }
      }

      &__image {
        margin: 6px;
        line-height: 86px;
        max-height: 74px;
        max-width: 74px;
      }
    }
  }

  &--CPG {
    & + .ui-search-spotlight {
      margin-top: 6px;
    }
  }

  &--CPG .carousel-mobile {
    position: relative;

    &-list {
      display: flex;
      margin: $andes-spacing-16 10px;
    }

    &-list-element {
      display: flex;
      vertical-align: middle;
    }
  }

  &--CPG #{$self}__item-generic-card,
  &--CPG #{$self}__item-generic-main {
    @include size(310px, 120px);

    background-color: $andes-white;
    border-radius: $border-radius-4;
    margin-right: 10px;
  }

  &--CPG #{$self}__item-generic__view-more {
    @include square(120px);

    padding: 15px;
    background-color: $andes-white;
    display: flex;
    justify-content: center;
    margin-right: 10px;
    align-items: end;
    border-radius: $border-radius-4;
    box-shadow: 0 1px 18px 0 $search-result-gallery-shadow-color;

    &__container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-top: 23px;

      .ui-search-icon--cpg-more g {
        fill: $andes-blue-500;
      }
    }

    &__title {
      font-size: $font-size-14;
      line-height: 1;
      font-weight: $font-weight-regular;
      text-align: center;
      color: $andes-gray-900;
      margin-top: 6px;
      text-wrap: nowrap;
    }
  }

  &--CPG #{$self}__item-generic-main {
    box-shadow: 0 1px 18px 0 $andes-gray-100;

    &__container {
      display: flex;
      justify-content: space-between;
      height: 100%;
    }

    &__data {
      display: flex;
      flex-direction: column;
      margin: 0 0 0 16px;
      justify-content: space-evenly;
      height: 100%;
      width: 186px;
      flex-grow: 1;
    }

    &__title {
      font-size: $font-size-16;
      font-weight: $font-weight-semibold;
      line-height: 1.5;
      letter-spacing: -0.2px;
      color: $andes-gray-900;
    }

    &__subtitle {
      font-size: $font-size-14;
      line-height: 1.29;
      letter-spacing: -0.1px;
      color: $andes-gray-900;
      font-weight: $font-weight-regular;
    }

    &__link {
      font-size: $font-size-14;
      line-height: 1.71;
      letter-spacing: -0.1px;
      color: $andes-blue-500;
      display: inline;
      font-weight: $font-weight-regular;

      &::after {
        content: '';
        border: solid $andes-blue-500;
        border-width: 0 1.3px 1.3px 0;
        display: inline-block;
        padding: 2px;
        transform: rotate(-45deg);
        margin: 0 0 2px 4px;
        position: relative;
        left: 3px;
      }
    }

    &__image {
      @include size(68px, 84px);

      margin: 22px 18px 0 0;
    }
  }

  &--CPG #{$self}__item-generic-card {
    box-shadow: 0 1px 18px 0 $andes-gray-100;

    &__container {
      display: flex;
    }

    &__data {
      margin: 20px 0 10px;
    }

    &__title {
      @include size(171px, 32px);

      font-size: $font-size-14;
      font-weight: $font-weight-light;
      line-height: 1.14;
      color: $search-gray-1000;
      margin: 0 20px 0 0;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    &__price {
      margin-top: 5px;

      .price-tag {
        display: flex;
      }

      .ui-search-price__original-value .andes-money-amount__fraction,
      .ui-search-price__original-value .andes-money-amount__currency-symbol {
        font-size: $font-size-12;
      }

      .#{$search-prefix}-price__second-line {
        display: flex;

        .price-tag-fraction,
        .price-tag-symbol {
          font-size: $font-size-24;
        }

        .#{$search-prefix}-price__part {
          color: $andes-gray-900;
        }

        .price-tag-symbol {
          margin-right: 6px;
        }

        .ui-search-price__discount {
          font-size: $font-size-12;
          margin-bottom: 3px;
          color: $search-green-100;
          font-weight: 400;
          letter-spacing: normal;
        }
      }

      .ui-search-price__original-value {
        margin-bottom: 5px;
        color: $search-gray-700;

        .price-tag-fraction,
        .price-tag-symbol {
          font-size: $font-size-12;
        }

        .price-tag-symbol {
          margin-right: 3px;
        }

        .price-tag-cents {
          font-size: $font-size-8;
          line-height: 0.4em;
        }
      }

      .item__discount {
        font-size: $font-size-10;
      }

      .price-tag-cents {
        font-weight: $font-weight-semibold;
        line-height: 0.7em;
      }
    }

    &__image {
      @include square(102px);

      margin: 9px;
      object-fit: contain;
    }
  }

  &--mosaic,
  &--mosaic#{$self}--CPG {
    .carousel-mobile-list {
      margin: 0 $andes-spacing-12 $andes-spacing-12;
    }
  }
}

.ui-search-seo-top-keywords-intervention {
  &__container {
    height: 92px;
    padding: $andes-spacing-16 0 $andes-spacing-16 $andes-spacing-16;
  }

  &__title {
    margin-bottom: $andes-spacing-8;
    font-weight: $font-weight-semibold;
    font-size: $font-size-14;
  }
}

.#{$search-prefix}-pricing-banner {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.#{$search-prefix}-pricing-banner__image {
  max-width: 492px;
  max-height: 80px;
  display: inline-block;
  overflow: hidden;
  border-radius: 4px;
  position: relative;
  cursor: pointer;
}

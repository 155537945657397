@import '~@andes/switch/index';

.#{$search-prefix}-animated-switch-v2 {
  &.andes-switch {
    padding: 0;
  }

  .andes-switch__input {
    height: 16px;
    width: 32px;
    border-radius: 25px;

    &::before {
      background-color: #b2b2b2;
    }

    &:checked::before {
      background-color: $search-dodger-blue;
    }

    &::after {
      width: 12px;
      height: 12px;
    }
  }
}

// sass-lint:disable no-qualifying-elements
p.#{$search-prefix}-carousel__item-generic-main__title {
  display: flex;
  align-items: center;
  gap: $andes-spacing-4;
  text-wrap: nowrap;
}

.#{$search-prefix}-carousel__item-generic-main__title {
  .#{$search-prefix}-item-generic-header__logo--supermarket {
    margin: 0 0 0 5px;
    width: 120px;
    height: 23px;
  }
}

.#{$search-prefix}-carousel {
  width: 100%;
  overflow: hidden;

  .andes-carousel-free {
    width: 100%;
    position: relative;
  }

  &__container {
    display: flex;
  }
}

@import '../../../styles/variables';

.#{$search-prefix}-footer-disclaimer {
  display: block;
  margin: $search-spacing-30 $search-spacing-10;

  &__text {
    color: $search-gray-350;
    display: inline-block;
    font-size: $font-size-14;
    font-weight: $font-weight-regular;
    line-height: 21px;
    text-align: center;
    width: calc(100% - $search-spacing-7);

    &::before {
      content: '*';
      font-size: 19px;
      margin-left: -7px;
      position: relative;
      right: $search-spacing-1;
      top: $search-spacing-2;
    }
  }
}

@import '../../../styles/variables';

// sass-lint:disable no-vendor-prefixes
.#{$search-prefix}-layout--stack {
  margin-top: -1px;
  padding: 0 0 8px;

  .ui-search-row__item .ui-search-result,
  .poly-card {
    &--mobile {
      box-shadow: 0 1px 2px 0px $search-gary-12;
      border-radius: 6px;
      margin: 1px 0;
      background-color: #fff;
    }

    .poly-card__content .poly-component__title h2 {
      font-size: 14px;
    }
  }

  .#{$search-prefix}-result {
    padding: 16px 0;
    display: flex;
    align-items: flex-start;
    min-height: 108px;
    box-shadow: none;
    margin: 1px 0;
    border-radius: unset;
    background: $andes-white;
    position: relative;

    &__item_wrapper {
      display: flex;
    }

    & > div {
      flex: 1;
    }

    &__item-image-container {
      padding: 0 16px;
      position: relative;
      display: table-cell;
      vertical-align: top;
    }

    &__image__link {
      display: flex;
      flex-direction: column;
      min-height: 100%;
      height: 100%;
    }

    &__image_container {
      width: 140px;
      max-width: 108px;
      position: relative;
      vertical-align: top;
      border-radius: 6px;
      margin: 0;
      overflow: hidden;

      img {
        border-radius: 6px;
        width: 100%;
        height: 108px;
        object-fit: contain;
      }

      &--core,
      &--cpg {
        img {
          height: auto;
          width: auto;
          max-width: 100%;
          max-height: 100%;
          margin: 0 auto;
        }
      }

      &--core {
        height: 100%;
        min-height: 108px;
      }

      &--cpg {
        min-height: 124px;
        max-height: 164px;
        max-width: unset;
        width: 124px;
        display: flex;
        text-align: center;
        position: relative;
        border-radius: 6px;

        .#{$search-prefix}-result__overlay-image {
          position: absolute;
          height: 100%;
          width: 100%;
          background: $search-gray-004;
        }
      }
    }

    &__crop-image {
      img {
        object-fit: cover;
        width: 100%;
      }
    }

    &__item-image-container--cpg,
    &__item-image-container--core {
      width: 140px;
      position: relative;
      display: table-cell;
      vertical-align: top;
    }

    &--cpg__item-wrapper,
    &--core__item-wrapper {
      display: table;
      width: 100%;
      height: 100%;
      min-height: 108px;
    }

    &__content {
      min-height: 108px;
      padding-right: 40px;

      &--core {
        margin-top: 0;
      }
    }

    &__link {
      width: 100%;

      &--pill-p {
        padding-bottom: 24px;

        .#{$search-prefix}-item__pub-label {
          position: relative;
          padding: 13px 12px 0 8px;
          float: right;
        }
      }
    }

    .#{$search-prefix}-item__highlight-label {
      &--pricing_rebates {
        font-size: $font-size-12;
      }
    }

    .#{$search-prefix}-item__group--rental-type {
      margin-bottom: 0;

      & .#{$search-prefix}-item__highlight-label {
        margin: 0 0 $search-spacing-6;
      }
    }

    .#{$search-prefix}-installments {
      font-size: $font-size-12;
    }

    .#{$search-prefix}-price {
      &__discount_prefix {
        font-size: $font-size-12;
      }

      &__discount {
        font-size: $font-size-12;
      }

      .price-tag-cents {
        font-size: $font-size-10;
        font-weight: $font-weight-semibold;
      }

      &__second-line--decimal .price-tag-cents {
        padding-top: 0;
        font-size: inherit;
        font-weight: inherit;
        line-height: unset;
      }
    }

    .#{$search-prefix}-item__highlight-label {
      &--pricing_rebates {
        display: inline-flex;
        margin-right: $search-spacing-6;
        margin-bottom: 0;
      }

      &.#{$search-prefix}-value-proposition__pill {
        margin-bottom: 0;
      }
    }

    &--cpg {
      padding: 12px 0;

      &__item-image-container {
        padding: 0;
        margin: 0 12px;
      }
    }

    &__cpg-wrapper {
      width: 100%;
      max-width: 200px;
      padding-right: $andes-spacing-12;

      &--ads {
        padding-bottom: $andes-spacing-12;
      }

      .#{$search-prefix}-result__content {
        min-height: 0;
        padding: 0;

        .#{$search-prefix}-item__group:last-child {
          padding-bottom: $andes-spacing-12;
        }
      }

      .#{$search-prefix}-item__group.#{$search-prefix}__group--add-to-cart {
        padding-bottom: 0;
      }
    }

    @media (width <=343px) {
      &__cpg-wrapper {
        max-width: 170px;
      }
    }
  }

  .#{$search-prefix}-item__group {
    &--vertical-highlight {
      flex-wrap: wrap;
    }

    &--rebates {
      margin-bottom: 12px;
    }
  }
}

.#{$search-prefix}-row--stack {
  .#{$search-prefix}-row--fade {
    opacity: 0.5;
    transition: opacity 1s ease-in-out;
  }

  .ui-search-row__item .ui-search-result,
  .poly-card {
    &--mobile {
      box-shadow: 0 1px 2px 0px rgba(0, 0, 0, 0.12);
      border-radius: 6px;
      margin: 1px 0;
      background-color: #fff;
    }

    .poly-card__content .poly-component__title h2 {
      font-size: 14px;
    }
  }

  .ui-row-card--core,
  .ui-row-card--mot {
    padding: $andes-spacing-12 0;
  }

  .ui-row-card--core,
  .ui-row-card--srv {
    padding: $andes-spacing-12 0;
  }

  .ui-row-item-info {
    padding-right: 0px;
  }

  .ui-row-card__content {
    padding: 4px 12px 4px 0;
  }
}

.#{$search-prefix}-row__decimal-price {
  .price-tag-decimal-separator {
    font-size: unset;
    position: unset;
  }

  .price-tag-cents {
    padding-top: 0;
    font-size: inherit;
    font-weight: inherit;
    line-height: unset;
    margin-left: 0;
  }
}

@media (width <=365px) {
  .#{$search-prefix}-item__group__element {
    &--shipping {
      width: 102%;
    }
  }
}

@import '../../../../styles/variables';

.ui-search-text-icon-filter-intervention {
  .andes-carousel-free {
    .andes-tag {
      background-color: $andes-white;
      border: none;
    }

    .andes-tag__avatar-icon-container {
      background-color: $andes-white;
      color: $andes-gray-550;
    }

    .andes-carousel-free__slide:not(:last-child) {
      margin-right: 8px;
    }
  }
}

@import '../../../../styles/variables';

.ui-search-seo-info-image-text-box-intervention-mobile {
  &__container {
    .seo-ui-keyword-summary-mobile__container {
      margin: 0;
    }

    .seo-ui-keyword-summary-mobile__image-container {
      margin: 0 $andes-spacing-12 0 0;
    }

    .seo-ui-keyword-summary-mobile__title-container {
      padding: 0;
    }

    .seo-ui-keyword-summary-mobile__header-container {
      padding: 0 0 $andes-spacing-16 0;
    }

    .seo-ui-keyword-summary-mobile__image-overlay,
    .seo-ui-keyword-summary-mobile__image {
      border-radius: $border-radius-6;
    }

    .seo-ui-keyword-summary-mobile__description-container {
      font-size: $font-size-14;
      line-height: 18px;

      .andes-expandable-section {
        margin-bottom: 0;

        & > :first-child {
          margin-bottom: $andes-spacing-16;
          padding: 0;
        }
      }

      .andes-expandable-section__simple-trigger .andes-typography {
        font-size: $font-size-14;
        line-height: 18px;
      }
    }
  }
}

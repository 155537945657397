@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.#{$search-prefix}-variations {
  @include text-crop(4px, 3px);

  color: $andes-gray-550;
  font-size: $font-size-12;
  font-weight: $font-weight-regular;
  line-height: $line-height-s;
}

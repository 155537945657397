@import '../../../styles/variables';

$search-seller-header-background-color: #f7f7f7;
$border-color-seller: #e5e5e5;

.#{$search-prefix}-header--seller {
  $self: &;

  padding: 15px 10px;
  background: $andes-white;
  background-color: $search-seller-header-background-color;
  border-bottom: 1px solid $border-color-seller;
  margin-bottom: 20px;
  color: $search-gray-666;

  &__logo {
    text-align: center;
    background: $andes-white;
    overflow: hidden;
    vertical-align: middle;
    display: inline-block;
    box-shadow: 0 0 1px 1px $border-color-seller;
    width: 96px;
    height: 70px;
  }

  &__image {
    max-height: 100%;
    max-width: 100%;
    position: relative;
    top: 50%;
    padding: 2px;
    transform: translateY(-50%);
  }

  &__info {
    position: relative;
    vertical-align: middle;
    display: inline-block;
    font-size: $font-size-14;
    font-weight: $font-weight-regular;
    margin-left: 14px;
  }

  &__strapline {
    font-size: $font-size-14;
    font-weight: $font-weight-regular;
    color: $search-gray-700;
  }

  &__title {
    font-size: 1.38em;
    font-weight: 600;
    line-height: 1;
  }

  &__action {
    font-size: 14px;
    font-weight: 400;
    margin-top: 3px;
    display: block;
  }

  &__description {
    font-size: $font-size-14;
    font-weight: $font-weight-regular;
    margin-top: 8px;
  }

  &.#{$search-prefix}-header--showcase {
    #{$self}__logo {
      vertical-align: top;
    }

    #{$self}__title {
      color: $search-gray-1000;
    }

    #{$self}__strapline {
      font-size: $font-size-12;
    }
  }

  &.#{$search-prefix}-header--stack {
    margin-bottom: 0;
  }
}

@import '../../../styles/variables';

$search-result-grid-shadow-color: rgb(0 0 0 / 10%);

// sass-lint:disable no-vendor-prefixes
// sass-lint:disable no-misspelled-properties
.#{$search-prefix}-layout--grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, max(49.8%));
  gap: 1px;
  margin-bottom: $search-spacing-1;

  li:first-child {
    margin-right: $search-spacing-1;
  }

  .#{$search-prefix}-item__group {
    margin-bottom: $andes-spacing-12;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .#{$search-prefix}-layout__item {
    width: calc(100%);
  }

  .andes-card {
    height: 100%;

    &__content {
      height: 100%;
    }
  }

  .#{$search-prefix}-result {
    overflow: hidden;
    padding: 0;
    height: 100%;

    &__image_container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: $andes-spacing-4 $andes-spacing-4 0;
      position: relative;
    }

    &__image_container img {
      max-height: 250px;
      width: auto;
      max-width: 100%;
      height: auto;
    }

    &__overlay-image {
      position: absolute;
      height: 100%;
      width: 100%;
      background: $search-gray-004;
    }

    &--core {
      display: flex;

      .#{$search-prefix}-result--core__item_wrapper {
        display: flex;
        flex-direction: column;

        .#{$search-prefix}-result__cpg-wrapper {
          margin-top: auto;
          margin-bottom: 35px;
        }

        .#{$search-prefix}-item__group--add-to-cart {
          padding: 0 12px;
          margin-bottom: 0;
        }

        .andes-input-stepper__complementary-text {
          font-size: 10px;
        }
      }
    }

    &__content {
      padding: $andes-spacing-16 $andes-spacing-12;
    }

    &__content--core {
      .#{$search-prefix}-price {
        &__part {
          margin-right: $search-spacing-5;
        }

        &__second-line__label {
          margin-top: $search-spacing-1;
        }
      }
    }

    &__content--pill-p {
      padding-bottom: 40px;
    }
  }

  .#{$search-prefix}-item__shipping {
    font-size: $font-size-12;
    font-weight: $font-weight-regular;
    line-height: $font-size-14;
  }

  .#{$search-prefix}-item__shipping--free {
    font-weight: $font-weight-semibold;
  }

  .#{$search-prefix}-item__title {
    line-height: 18px;
    max-height: 72px;
  }

  .#{$search-prefix}-bookmark {
    right: -$search-spacing-1;
    top: $search-spacing-1;
  }

  .#{$search-prefix}-bookmark__btn::before {
    content: '';
    background: rgba($andes-white, 0.9);
    width: $andes-spacing-32;
    height: $andes-spacing-32;
    position: absolute;
    top: 7px;
    right: 9px;
    border-radius: 50%;
    z-index: $z-index-base;
  }

  .#{$search-prefix}-bookmark .#{$search-prefix}-icon--bookmark {
    z-index: $z-index-s;
  }

  .#{$search-prefix}-bookmark__icon-bookmark,
  .#{$search-prefix}-bookmark__icon-bookmark-fill {
    top: $andes-spacing-16;
    left: $andes-spacing-16;
  }

  .#{$search-prefix}-item__pub-label {
    right: $andes-spacing-12;
  }

  .#{$search-prefix}-item__promise__text--splitted {
    .#{$search-prefix}-item__promise__text:first-of-type {
      margin-right: -$andes-spacing-4;
    }
  }

  .#{$search-prefix}-item__promise__text {
    padding-left: $andes-spacing-4;
    padding-right: $andes-spacing-4;
  }

  .#{$search-prefix}-rebates-rebate {
    max-width: 142px;
    display: flex;

    &__pill {
      font-size: $font-size-12;
      margin-right: $search-spacing-6;
      display: inline;
      box-decoration-break: clone;
      line-height: $font-size-14;
      min-height: $search-spacing-18;
    }

    &__text,
    &__text_nl {
      display: inline-block;
      font-size: $font-size-10;
      font-weight: 400;
    }
  }
}

@media (width <= 320px) {
  .#{$search-prefix}-layout--grid {
    .#{$search-prefix}-result {
      &__image_container img {
        max-height: 220px;
        height: auto;
      }
    }
  }
}

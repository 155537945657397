$search-fullpopup-z-index: 1020;
$search-fullpopup-animation-duration: 0.2s;

.#{$search-prefix}-fullpopup {
  z-index: $search-fullpopup-z-index;
  margin-left: 0;
  width: 100%;
  display: table;
  border-collapse: collapse;
  border-spacing: em(10);
  animation-duration: $search-fullpopup-animation-duration;
  border-radius: $border-radius-6;
  position: absolute;

  &-down {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    box-shadow: 0 -6px 16px 0 $search-gray-r010;
    .andes-snackbar__message {
      padding: 0;
    }
    .andes-snackbar__children {
      padding: 0;
    }
  }

  &-up {
    top: 0px;
    box-shadow: 0 6px 16px 0 $search-gray-r010;
    .andes-snackbar__message {
      padding: 0;
    }
    .andes-snackbar__children {
      padding: 0;
    }
  }

  &-up-sticky {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1020;
  }
  &-up-float {
    position: absolute;
    width: 100%;
  }
  &-down--animate-show {
    animation-name: search-fullpopup-bottom-show;
    animation-timing-function: ease-in-out;
  }

  &-down--animate-hide {
    animation-name: search-fullpopup-bottom-hide;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
  }

  &-up--animate-show {
    animation-name: search-fullpopup-top-show;
    animation-timing-function: ease-in-out;
  }

  &-up--animate-hide {
    animation-name: search-fullpopup-top-hide;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
  }
}

@keyframes search-fullpopup-bottom-show {
  from {
    opacity: 0;
    transform: translate3d(-50%, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(-50%, 0, 0);
  }
}

@keyframes search-fullpopup-bottom-hide {
  0% {
    transform: translate3d(-50%, 0, 0);
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: translate3d(-50%, 100%, 0);
  }
}

@keyframes search-fullpopup-top-show {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes search-fullpopup-top-hide {
  0% {
    transform: translate3d(0, 0, 0);
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
}

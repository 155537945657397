.sort-option-menu--link {
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 14px;

  .ui-search-icon--order {
    fill: #3483fa;
    width: 14px;
    height: 14px;
    margin-right: 8px;
  }
}

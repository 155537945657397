$border-color-dropdown: #e5e5e5;

.#{$search-prefix}-filter-list {
  &__applied-filters {
    padding-top: $andes-spacing-20;
    border-top: 1px solid $andes-gray-100;
  }

  &__top-brand {
    .andes-list {
      &__item {
        display: none;
      }
    }

    .andes-modal__header {
      padding: 25px 8px 11px 60px;
      &__contents {
        padding-left: 20px;
        .andes-modal__title {
          font-size: 18px;
        }
      }
    }
  }
}

.#{$search-prefix}-filter {
  &.andes-list__item {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 32px;
    box-shadow: none;

    .andes-list__item-action::before {
      z-index: $z-index-s;
    }

    .#{$search-prefix}-icon {
      display: inline-block;

      &--cockade {
        position: relative;
        margin-bottom: -1px;
      }
    }

    &:has(.ui-search-animated-switch-v2) {
      .andes-list__item-text {
        font-weight: $font-weight-normal;
      }

      .andes-list__item-tertiary {
        position: initial;
      }
    }
  }

  &--sorts_container {
    border-bottom: 2px solid $search-gray-r010;
  }

  .andes-list__item-image-container {
    max-height: 18px;
  }

  .andes-list__item-tertiary {
    right: $andes-spacing-8;
    position: relative;
  }

  &--expandable {
    .andes-list__item-tertiary {
      transform: rotate(90deg);
      padding: 0 23px 0 25px;
    }

    &.#{$search-prefix}-filter--expanded {
      .andes-list__item-tertiary {
        transform: rotate(-90deg);
      }
    }
  }

  &__values-list {
    padding-top: $andes-spacing-16;
    padding-bottom: $andes-spacing-16;
    border-top: 1px solid $andes-gray-100;
    color: $andes-blue-500;
    background-color: $search-color-filter;

    .ui-search-item__fulfillment {
      &::after,
      &::before {
        margin: 0;
      }
    }

    .ui-search-icon--full {
      height: 18px;
      width: 57px;
    }
  }

  &__value {
    display: block;
    padding-top: $andes-spacing-20;
    padding-bottom: $andes-spacing-20;
    padding-left: $andes-spacing-32;

    &:focus {
      outline: none;
    }
  }

  &--new-buyer-shipping-cost-highlighted-free {
    .ui-search-filter__title {
      font-weight: $font-weight-semibold;
      color: $search-green-100;
    }

    .ui-search-filter__subtitle {
      line-height: $line-height-18;
    }
  }

  &--highlighted {
    .#{$search-prefix}-filter__title {
      display: inline-block;

      .ui-search-icon {
        position: relative;

        // sass-lint:disable nesting-depth
        &--full {
          width: 55px;
          height: 18px;
          top: 5px;
          right: 1px;
        }
        
        &--price_per_quantity {
          top: 3px;
        }
        
        &--issues_invoice {
          top: 4px;
        }
      }
    }

    .#{$search-prefix}-filter__meli-plus {
      display: flex;

      .ui-search-icon {
        position: relative;
      }
    }

    .andes-list__item-tertiary {
      height: 16px;
      bottom: 1px;
    }

    &.#{$search-prefix}-filter--SHIPPING_ORIGIN_HIGHLIGHTED {
      &.andes-list__item {
        padding-top: 34px;
        margin-bottom: 12px;
      }
    }

    &.#{$search-prefix}-filter--shipping_highlighted_fulfillment {
      &.andes-list__item {
        padding-top: 14px;
        padding-bottom: 14px;
      }

      .#{$search-prefix}-filter__title {
        letter-spacing: -0.2px;
      }

      .andes-list__item-tertiary {
        bottom: 0;
        top: 1px;
      }

      .andes-list__item-image-container {
        max-height: fit-content;
      }
    }

    &.#{$search-prefix}-filter--coin_highlighted {
      label {
        display: flex;
        background-color: $search-yellow-fff1cb;
        border-radius: 2px;
        color: $search-brown-a47b2e;
        height: 20px;
        font-weight: 600;
        padding-left: 4px;
        padding-right: 4px;
        align-items: center;
        justify-content: center;
        font-size: 12px;

        svg {
          margin-left: 5px;
        }
      }
    }

    &.#{$search-prefix}-filter--WITH_VOLUME_DISCOUNT_HIGHLIGHTED {
      .#{$search-prefix}-icon {
        margin-right: 5px;
        height: 20px;
        width: 20px;
        min-width: 20px;
        position: relative;
        top: 1px;
      }

      .#{$search-prefix}-styled-label {
        font-size: $font-size-18;
        font-weight: $font-weight-light;
        line-height: 21px;
      }

      .andes-list__item-image-container {
        max-height: fit-content;
      }

      &.andes-list__item {
        padding-top: $search-spacing-18;
        padding-bottom: $search-spacing-18;
      }
    }

    .#{$search-prefix}-filter__subtitle {
      display: inherit;
      font-size: $font-size-14;
      color: $andes-gray-900;
      margin-top: 6px;
    }

    .#{$search-prefix}-item__highlight-label {
      font-size: $font-size-14;
      height: 20px;
      padding-top: 3px;
      position: relative;
      bottom: 1px;
    }

    &.#{$search-prefix}-filter--RENTAL_TYPE_HIGHLIGHTED .#{$search-prefix}-item__highlight-label {
      font-size: $font-size-16;
    }

    .andes-switch::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
  }
}

.#{$search-prefix}-range-filter {
  &__content {
    padding: 0 30px;
    display: flex;
    flex-flow: row nowrap;
  }

  &__text-field {
    display: flex;
    flex-direction: row;
    align-items: center;

    .andes-form-control__field {
      width: 100%;
    }
  }

  &__text-field:first-child::after {
    content: '\2014';
    float: left;
    font-size: $font-size-16;
    line-height: 2px;
    margin: 0 5px;
    padding: $search-spacing-12;
    color: $search-gray-r060;
  }

  & .andes-dropdown__arrow {
    display: none;
  }

  & .andes-dropdown {
    display: flex;
    align-items: center;

    &--compact .andes-dropdown__trigger {
      border: 1px solid $border-color-dropdown;
      color: $andes-gray-900;
      border-radius: $border-radius-3;
      padding: $search-spacing-8 $search-spacing-10;
      height: 36px;
      min-width: 130px;
      font-size: $font-size-12;
      align-items: center;
    }

    &--compact.andes-dropdown--open .andes-dropdown__trigger {
      box-shadow: none;
    }

    &__popover {
      max-height: 147px;
      overflow-y: auto;
      padding: 0;
    }
  }

  .andes-floating-menu {
    min-width: 137px;
  }
}

.filter-option-menu--link {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: $font-size-14;

  .ui-search-icon--filter {
    fill: $andes-blue-500;
    width: 14px;
    height: 14px;
    margin-right: 8px;
  }
}

.andes-list__item-second-column {
  .ui-search-animated-switch-v2 {
    padding: 0 10px;
  }
}

@import '../../../styles/variables';

.#{$search-prefix}-cross-site-modal {
  &__message {
    color: $andes-gray-550;
    font-size: $font-size-18;
    line-height: 1.2em;
    margin-top: 15px;
  }

  &__button:first-of-type {
    margin-top: 35px;
    line-height: 18px;
  }

  &__button:last-of-type {
    padding-right: 0;
    padding-left: 0;
  }

  &__message--strong {
    display: block;
    font-weight: $font-weight-bold;
  }
}

.andes-modal--light {
  text-align: center;
  padding: 56px 24px;
  border-radius: 4px;
  width: calc(100% - 40px);
}

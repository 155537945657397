@import '../../../styles/variables';

.#{$search-prefix}-listing-disclaimer {
  display: flex;

  &__card {
    display: flex;
    background-color: $andes-white;
    padding: 11px 15px 7px;
    border-radius: $border-radius-4;
    margin: 0 $andes-spacing-12 $andes-spacing-16;
  }

  &__icon {
    transform: translateY(-1px);
  }

  &__text {
    font-size: $font-size-12;
    color: $andes-gray-550;
    font-weight: $font-weight-regular;
    display: inline-block;
    width: calc(100% - 26px);
    margin-left: 9px;
    position: relative;
    bottom: 1px;
    line-height: 15px;
  }

  &__card--stack,
  &__card--grid {
    border-radius: 0;
    margin: 4px 0 0;
    width: 100%;
  }

  &__card--gallery {
    margin-left: $andes-spacing-16;
    margin-right: $andes-spacing-16;
  }
}

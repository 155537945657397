@import '../../../styles/variables';
@import '~@andes/money-amount/index';

$search-result-mosaic-shadow-color: rgb(0 0 0 / 10%);

// sass-lint:disable no-vendor-prefixes
.#{$search-prefix}-layout--mosaic {
  display: flex;
  padding: 0 6px 2px;
  margin-top: 12px;

  &__group {
    padding: 0;
    margin: 0;
    width: 50%;
  }

  .#{$search-prefix}-item__group {
    margin-bottom: $andes-spacing-12;

    &:last-child {
      margin-bottom: 0;
    }
  }

  // Melicoin
  .#{$search-prefix}-melicoin {
    border-radius: 3px;
    display: flex;
    font-weight: 600;
    padding: 2px 4px;
    width: fit-content;
    background-color: $search-yellow-fff1cb;
    margin-bottom: 8px;

    &__label {
      display: flex;
      align-items: center;
      line-height: 15px;
      font-size: $font-size-12;
      font-weight: $font-weight-semibold;
      color: $search-brown-a47b2e;
    }

    &__spaceFinal {
      padding-right: 2px;
    }

    &__spaceStart {
      padding-left: 2px;
    }

    &__spaceMiddle {
      padding-right: 2px;
      padding-left: 2px;
    }

    .#{$search-prefix}-icon--icon-melicoin {
      margin-left: 4px;
    }

    .andes-money-amount--cents-comma {
      line-height: 15px;
      font-weight: 600;
    }

    .andes-money-amount__currency-symbol {
      font-size: $font-size-12;
    }

    .andes-money-amount__fraction {
      font-size: $font-size-12;
    }

    .andes-money-amount__cents {
      font-size: $font-size-12;
    }

    .andes-money-amount__fraction {
      font-size: $font-size-12;
    }
  }

  .#{$search-prefix}-melicoin-split {
    margin-bottom: 1px;
  }

  // CPG

  .#{$search-prefix}-result--cpg {
    display: flex;

    &__item_wrapper {
      display: flex;
      flex-direction: column;
      height: var(--max-card-height, auto);
    }

    .#{$search-prefix}-result__link {
      height: 100%;
    }

    .#{$search-prefix}-result__content--cpg {
      padding-bottom: 8px;
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    .ui-search-item__group--cpg {
      margin-top: auto;
    }

    .#{$search-prefix}-item__group {
      margin-bottom: $andes-spacing-16;

      &--title {
        margin-bottom: $andes-spacing-12;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    .#{$search-prefix}-item__group__element {
      &.ui-search-price {
        margin-bottom: $andes-spacing-12;
      }
    }
  }

  &__group--row {
    .#{$search-prefix}-layout__item {
      display: inline-block;
      width: calc(50%);
    }

    .#{$search-prefix}-layout__item-suggested {
      width: 100%;
      height: 150px;
      background-color: $search-gray-900;
    }
  }

  .#{$search-prefix}-result {
    box-shadow: 0 10px 16px 0 $search-result-mosaic-shadow-color;
    border-radius: 6px;
    margin: 0 6px 12px;
    padding: 0;
    overflow: hidden;

    &__image_container {
      border-radius: 3px 3px 0 0;
      margin: 0;
      font-size: 1px;
    }

    &__image_container img {
      border-radius: 6px 6px 0 0;
      height: auto;
      max-height: 380px;
      max-width: 100%;
      width: 100%;
      object-fit: contain;
    }

    &__image_container--cpg img {
      height: 162px;
      object-fit: contain;
    }

    &__content {
      padding: $andes-spacing-16 $andes-spacing-12;
    }

    &__content--core {
      .#{$search-prefix}-price {
        &__part {
          margin-right: 5px;
        }

        &__second-line__label {
          margin-top: 1px;
        }
      }
    }

    &__content--pill-p {
      padding-bottom: 40px;
    }
  }

  .#{$search-prefix}-item__shipping {
    font-size: $font-size-12;
    font-weight: $font-weight-regular;
    line-height: $font-size-14;
  }

  .#{$search-prefix}-item__shipping--free {
    font-weight: $font-weight-semibold;
  }

  .#{$search-prefix}-item__title {
    line-height: 18px;
    max-height: 72px;
  }

  .#{$search-prefix}-item__attributes li {
    color: $andes-gray-550;
    display: inline;
    list-style: none;

    &::before {
      content: '\b7';
      font-size: 200%;
      vertical-align: sub;
      line-height: 12px;
      margin-right: 2px;
      margin-left: 3px;
    }

    &:first-child::before {
      content: '';
      display: none;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .#{$search-prefix}-bookmark {
    right: 6px;
  }

  .#{$search-prefix}-bookmark__btn::before {
    content: '';
    background: rgba($andes-white, 0.9);
    width: 30px;
    height: 30px;
    position: absolute;
    top: 7px;
    right: 9px;
    border-radius: 50%;
    z-index: $z-index-base;
  }

  .#{$search-prefix}-bookmark .#{$search-prefix}-icon--bookmark {
    z-index: $z-index-s;
  }

  .#{$search-prefix}-bookmark__icon-bookmark,
  .#{$search-prefix}-bookmark__icon-bookmark-fill {
    top: 15px;
    left: 17px;
  }

  .#{$search-prefix}-item__attributes--srv {
    font-weight: $font-weight-regular;
  }

  .#{$search-prefix}-item__attributes--mot {
    font-weight: $font-weight-regular;
  }

  .#{$search-prefix}-item__pub-label {
    right: 18px;
  }

  .#{$search-prefix}-item__attributes--res li {
    color: $search-text-color;
    font-weight: $font-weight-semi-bold-light;
  }

  .#{$search-prefix}-item__location {
    color: $search-res-location-color;
    font-size: $font-size-12;
    line-height: $line-height-16;
    font-weight: $font-weight-semi-bold-light;
  }

  .#{$search-prefix}-item__promise__text--splitted {
    .#{$search-prefix}-item__promise__text:first-of-type {
      margin-right: -$andes-spacing-4;
    }
  }

  .#{$search-prefix}-item__promise__text {
    padding-left: $andes-spacing-4;
    padding-right: $andes-spacing-4;
  }

  .#{$search-prefix}-rebates-rebate {
    max-width: 142px;
    display: flex;

    &__pill {
      font-size: $font-size-12;
      margin-right: $search-spacing-6;
      display: inline;
      box-decoration-break: clone;
      line-height: $font-size-14;
      min-height: $search-spacing-18;
    }

    &__text,
    &__text_nl {
      display: inline-block;
      font-size: $font-size-10;
      font-weight: 400;
    }
  }
}

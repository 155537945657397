@import 'seo-smart-tabs';

.ui-search-seo-smart-tabs {
  border-radius: $border-radius-6;

  &__container {
    padding: $andes-spacing-24 $andes-spacing-16 0;
    margin-bottom: $andes-spacing-32;
  }
}

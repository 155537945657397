// sass-lint:disable no-misspelled-properties
@import '../../../../../styles/variables';

.ui-search-meliplay-intervention--stack {
  display: flex;
  gap: $search-spacing-10;
  padding: $search-spacing-12;

  .ui-search-item__highlight-label__text {
    padding: $search-spacing-2 $search-spacing-4;
    font-weight: 600;
    border-radius: 3px;
    font-size: $font-size-12;
    line-height: $line-height-15;
  }

  &__image {
    width: 144px;
    max-height: 81px;
    object-fit: cover;
    border-radius: 6px;
  }

  &__data {
    display: flex;
    flex-direction: column;
    gap: $search-spacing-12;
  }

  &__title {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;

    &-text {
      line-height: $line-height-15;
      font-size: $font-size-14;
      font-weight: $font-weight-normal;
      color: $search-gray-1000;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      word-break: break-word;
    }
  }

  &__description {
    color: $andes-text-color-secondary;
    font-size: $font-size-12;
  }

  &__label {
    display: flex;
    gap: $search-spacing-4;
    align-items: center;

    &-text {
      color: $search-green-100;
      font-weight: $font-weight-normal;
      font-size: $font-size-12;
      line-height: $line-height-15;
    }
  }

  &__button {
    border: 1px solid !important;
    max-width: 160px;

    .ui-search-icon--play {
      margin-right: $search-spacing-7;
    }
  }

  @media (width <= 335px) {
    &__button {
      font-size: 11px;
      padding: 0;
      height: 25px;

      .ui-search-icon--play {
        margin-right: $search-spacing-2;
      }
    }
  }

  @media (width <= 320px) {
    &__image {
      width: 124px;
    }

    &__label {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

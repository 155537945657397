@import '../styles/base';
@import '../label/styles/label.mobile.scss';
@import '../highlight/styles/highlight.mobile';
@import './shared';

.meli-plus,
.meli_plus,
.meliplus {
  svg {
    width: auto;
    height: 14px;
  }
}

.fulfillment,
.ui-fulfillment-pill {
  svg {
    width: auto;
    height: $andes-spacing-12;
  }

  .ui-pb-label {
    vertical-align: baseline;
  }
}
.ui-tradein-pill {
  line-height: normal;
  margin-bottom: 8px;
  margin-top: -4px;
  .ui-pb-label {
    font-size: $font-size-12;
  }
}

.ui-new-buyer-pill {
  line-height: 0.8;
  .ui-pb-label {
    font-size: $font-size-12;
    vertical-align: baseline;
    display: inline-block;
    font-weight: $font-weight-regular;
  }

  &.ui-pb-label-builder {
    padding-right: 1px;
  }
}
@import '../../../../styles/variables';
@import './highlight-label';

.ui-row-item__highlight-label--financeable_by_mc {
  font-size: $font-size-12;
  border-radius: 2px;
  line-height: $line-height-15;
}

.#{$search-prefix}-item__highlight-label {
  font-size: $font-size-10;

  &--TIME_LABEL_HIGHLIGHT {
    font-size: $font-size-12;
  }

  &--meli_choice {
    padding-right: 4px;
  }

  .#{$search-prefix}-icon--black-friday {
    position: relative;
    top: 1.5px;
    margin-right: 4px;
  }

  .#{$search-prefix}-icon--gift {
    margin-right: $search-spacing-4;
  }

  &--composed-title {
    display: block;
  }

  .#{$search-prefix}-icon--cart-volume-discount {
    margin-right: 8px;
  }

  .#{$search-prefix}-icon--hot-sale {
    width: 12px;
    height: 10px;
    position: relative;
    top: 1.3px;
    margin-right: 4px;
  }

  &--repurchase {
    position: absolute;
    top: $search-spacing-6;
    left: $search-spacing-6;
  }

  &--seen {
    position: absolute;
    &.ui-search-result__seen-label {
      top: $andes-spacing-8;
      left: $andes-spacing-8;
    }
  }
}

.#{$search-prefix}-item__highlight-group {
  margin-bottom: 0;

  &.#{$search-prefix}-item__group__element {
    margin: $search-spacing-2 0 $andes-spacing-12;
  }
}

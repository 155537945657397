@import '../../../styles/variables';
@import '../../../styles/mixins';

.#{$search-prefix}-toolbar {
  background-color: $andes-white;

  &--border {
    border-bottom: 1px solid $andes-gray-070;
  }

  &--eshops-shadow {
    position: relative;
    box-shadow: 0px 6px 16px 0px $search-gray-r010;
  }

  &--two-btn {
    .#{$search-prefix}-toolbar__action {
      min-width: 50%;
    }
  }

  &__actions {
    align-content: center;
    display: flex;
    justify-content: space-around;
    width: 100%;
    padding: 0 4px;
  }

  &__action {
    position: relative;
    flex: 1 1 auto;
    width: auto;
    min-width: 0;

    &::after {
      content: '';
      position: absolute;
      bottom: 16px;
      height: 20px;
      right: 0;
      border-right: 1px solid $search-line-color;
    }

    &:last-child::after {
      display: none;
    }
  }

  .#{$search-prefix}-modal__link {
    @include toolbar-buttons;
  }

  .#{$search-prefix}-modal__icon {
    @include toolbar-buttons-icons;
  }

  .#{$search-prefix}-modal__label {
    @include toolbar-buttons-label;
  }

  .#{$search-prefix}-toolbar__action--filter .#{$search-prefix}-modal__icon {
    margin-right: 10px;
  }

  //specific styles for h1 experiment(spotlight inside toolbar)
  &--has-spotlight & {
    &__actions {
      padding: 0;
    }

    &__action {
      position: relative;
      flex: 1 1 auto;
      width: initial;
      min-width: initial;

      &::after {
        content: '';
        position: absolute;
        bottom: 16px;
        height: 20px;
        right: 0;
        border-right: 1px solid $search-line-color;
      }

      &:last-child::after {
        display: none;
      }
    }
  }

  &--has-spotlight {
    .ui-search-modal__icon {
      display: none;

      @media (min-width: $breakpoint-360) {
        display: initial;
      }
    }
  }
}

.#{$search-prefix}-breadcrumb {
  &__chevron {
    @include size(0.6em, 0.6em);

    transform: translateY(1px);
  }

  &__title {
    display: none;
  }

  .andes-breadcrumb {
    margin-left: $andes-spacing-16;
    margin-bottom: $andes-spacing-32;
    font-weight: $font-weight-regular;
    line-height: 1.45;

    & .andes-breadcrumb__chevron {
      margin: 0 4px;
      padding: 0;
      display: inline;
      float: none;
      stroke: $andes-gray-900;
      stroke-width: 1;
    }

    & .andes-breadcrumb__item {
      display: inline;
    }

    .andes-breadcrumb__link,
    & .andes-breadcrumb__label {
      color: $andes-gray-900;
      float: none;
      font-size: 14px;
    }
  }
}

@import 'common';

.ui-search-more-info-intervention {
  background-color: $andes-white;
  height: 198px;
  margin-block-end: $andes-spacing-16;

  &__header {
    gap: $andes-spacing-4;
    padding-block-start: $andes-spacing-20;
    padding-inline: $andes-spacing-16;

    & .ui-search-icon--pharma {
      height: 20px;
      width: 20px;
    }
  }

  &__title {
    font-size: $font-size-18;
  }

  &__content {
    padding-block: $andes-spacing-16;

    .andes-carousel-free {
      margin: -4px;
      padding: 4px;
      padding-inline-start: $andes-spacing-20;
      padding-inline-end: $andes-spacing-16;
      width: 100%;
    }
  }

  &__card {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
    gap: $andes-spacing-12;
    width: 204px;
  }

  &__footer {
    padding-block-end: $andes-spacing-20;
    padding-inline: $andes-spacing-16;
  }
}

@import '../../../styles/variables';
@import '../../../styles/mixins';

.#{$search-prefix}-main-header-exp {
  padding: 0 $andes-spacing-16;
  margin: $andes-spacing-16 0;

  &__title {
    margin: 0;
    font-size: $font-size-base;
    font-weight: $font-weight-semibold;
    color: $andes-gray-900;
  }

  &__subtitle {
    text-transform: uppercase;
    font-size: $font-size-10;
    font-weight: $font-weight-semibold;
    color: $andes-gray-550;
  }

  &__link {
    color: $search-gray-1000;
    pointer-events: none;
  }

  &--mosaic {
    padding: 0 $andes-spacing-12;

    & + .#{$search-prefix}-applied-filters {
      padding: 0 $andes-spacing-12;
    }
  }

  & + .#{$search-prefix}-carousel {
    .slick-slide {
      padding-top: 0;
    }
  }

  & + .#{$search-prefix}-carousel--CPG {
    .carousel-mobile-list {
      margin-top: 0;
    }
  }

  .mr-5 {
    margin-right: 5px;
  }

  .content-icon {
    margin-left: 5px;
    display: inline-block;
    position: relative;
    width: 55px;

    svg {
      height: 16px;
      position: absolute;
      top: -11px;
      width: 45px;
    }
  }
}
